import React, {Component} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import Style from "../constants/style";

const Container = styled.div`
	width: 350px;
	border-radius: ${Style.borderRadius}px;
	overflow: hidden;
	color: white;
`;

const IconContainer = styled.div`
	font-size: 32px;
`;

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LeftContainer = styled.div`
`;

const RightContainer = styled.div`
	margin-left: 20px;
	width: 100%;
`;

const Title = styled.div`
	font-size: 20px;
	font-weight: 600;
`;

const SubTitle = styled.div`
	font-size: 16px;
`;

class SuccessNotification extends Component {
	render() {
		return (
			<Container>
				<div className="notification-success n-child">
					<div className="notification-content">
						<div className="notification-close"/>
						<FlexContainer>
							<LeftContainer>
								<IconContainer>
									<FontAwesomeIcon icon={faCheckCircle}/>
								</IconContainer>
							</LeftContainer>
							<RightContainer>
								<Title>Success</Title>
								<SubTitle>Your progress has been saved!</SubTitle>
							</RightContainer>
						</FlexContainer>

					</div>
				</div>
			</Container>
		);
	}
}

SuccessNotification.propTypes = {};

export default SuccessNotification;
