import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './components/Calendar/CustomCalendarStyles.scss';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faHome,
	faTree,
	faShoppingCart,
	faCalendar,
	faSnowman,
	faAward,
	faLightbulb,
	faDharmachakra,
	faChevronUp,
	faCheck,
	faChevronLeft,
	faChevronRight,
	faCommentAlt,
	faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';
import {Analytics} from "./utils/Analytics";
import LandingPage from "./components/LandingPage";
import PackageThemePage from "./components/PackageThemePage";
import PackageHomeSizePage from "./components/PackageHomeSizePage";
import PackageReviewPage from "./components/PackageReviewPage";
import PackageUpsellPage from "./components/PackageUpsellPage";

library.add(faHome, faTree, faShoppingCart, faCalendar, faSnowman, faAward, faLightbulb, faDharmachakra, faChevronUp, faCheck, faChevronLeft, faChevronRight, faCommentAlt, faMapMarkerAlt);

Analytics.init();

ReactDOM.render(
	<Router>
		<Switch>
			<Route exact path="/" component={LandingPage}/>
			<Route exact path="/fullhome" component={(props) => <PackageHomeSizePage {...props} />}/>
			<Route exact path="/fullhome/size" component={(props) => <PackageHomeSizePage {...props} />}/>
			<Route exact path="/fullhome/theme" component={(props) => <PackageThemePage {...props} isGround={false} />}/>
			<Route exact path="/fullhome/options" component={(props) => <PackageUpsellPage {...props} isGround={false} />}/>
			<Route exact path="/fullhome/review" component={(props) => <PackageReviewPage {...props} isGround={false} />}/>
			<Route exact path="/ground" component={(props) => <PackageThemePage {...props} isGround={true} />}/>
			<Route exact path="/ground/theme" component={(props) => <PackageThemePage {...props} isGround={true} />}/>
			<Route exact path="/ground/options" component={(props) => <PackageUpsellPage {...props} isGround={true} />}/>
			<Route exact path="/ground/review" component={(props) => <PackageReviewPage {...props} isGround={true} />}/>
			<Route component={App}/>
		</Switch>
	</Router>,
	document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
