import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Style from "../constants/style";
import {ScreenSize} from "../constants/screensize";

const ButtonContainer = styled.button`
	overflow: hidden;
	display: inline-block;
	border: 0;
	padding: 0;
	text-decoration: none;
	box-shadow: rgba(0, 0, 0, 0.2) 0 1px 0;
	cursor: pointer;
	visibility: visible;
	user-select: none;
	outline: none;
	width: 100%;
	border-radius: 25px;
	background: ${Style.primaryGreen};
	
	:disabled {
		background: lightgray;
		color: white;
	}
	
	:disabled:hover {
		cursor: not-allowed;
	}
	
	:not(:disabled):hover {
		background: #80cf1c !important;
	}

	:not(:disabled):active {
    background: ${Style.primaryGreenHighlight} !important;
    color: white !important;
	}
`;

const ButtonSpan = styled.span`
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	position: relative;
	display: block;
	height: 30px;
	font-weight: bold;
	box-shadow: rgba(255, 255, 255, 0.25) 0 1px 0 inset;
	min-height: 50px !important;
	font-size: 20px !important;
	line-height: 50px !important;
	border-radius: 25px;
	
	color: ${props => props.disabled ? 'white' : Style.darkGreen};
	text-shadow: none !important;

	:not(:disabled):active {
    color: white !important;
	}
	
	@media (max-width: ${ScreenSize.phone}px) {
		font-size: 18px !important;
	}
`;

class Button extends Component {
	render() {
		return (
			<ButtonContainer disabled={this.props.disabled} onClick={this.onClick}>
				<ButtonSpan disabled={this.props.disabled}>
					{this.props.children}
				</ButtonSpan>
			</ButtonContainer>
		);
	}

	onClick = () => {
		if (!this.props.disabled) {
			this.props.onClick();
		}
	};
}

Button.propTypes = {
	disabled: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired
};

Button.defaultProps = {
	disabled: false,
};

export default Button;
