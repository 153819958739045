import React, {Component} from 'react';
import styled from 'styled-components';
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';

const Container = styled.div`
	color: lightgray;
	font-size: 18px;
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: ${props => props.absolute ? 'flex-end' : 'center'};
	align-items: ${props => props.absolute ? 'flex-end' : 'center'};
	position: ${props => props.absolute ? 'absolute' : 'flex'};
	right: 0;
	padding-bottom: ${props => props.absolute ? 4 : 0}px;
	padding-right: ${props => props.absolute ? 4 : 0}px;

	:hover {
		color: black !important;
		cursor: pointer;
	}
`;

class RemoveButton extends Component {
	render() {
		return (
			<Container {...this.props}>
				<FontAwesomeIcon icon={faTimes}/>
			</Container>
		);
	}
}

RemoveButton.propTypes = {
	absolute: PropTypes.bool.isRequired
};

RemoveButton.defaultProps = {
	absolute: true
};

export default RemoveButton;
