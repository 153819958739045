export const PackageHelper = {
	getFullHomeFullListPackageItems() {
		return [
			"Entire gutterline with lights",
			"4 Bushes with net lights",
			"2 Decorations",
			"1 Tree with mini string lights, trunk wrapped",
			"1 Standard Wreath",
			"Custom-to-Home wiring",
			"Mechanical Timer",
			"All extension cords",
			"All clips",
			"Setup & Teardown",
			"A dedication to actively fix setups"
		]
	},

	getHighLevelPackageItemsFor(type) {
		let items;

		if (type === "ground") {
			items = [
				"4 Bushes with net lights",
				"2 Decorations",
				"1 Standard Wreath",
				"Custom-to-Home wiring",
				"Mechanical Timer",
				"All extension cords",
				"All clips",
				"Setup & Teardown",
				"A dedication to actively fix setups"
			];
		} else if (type === "fullhome") {
			items = [
				"EVERYTHING in Ground Package, PLUS:",
				"Entire gutterline with lights (includes areas above garages & porches)",
				"1 Tree with mini string lights, trunk wrapped",
			];
		} else { // byo
			items = [
				"Entire gutterline with lights (includes areas above garages & porches)",
				"3+ Net Lights for Medium Bushes",
				"2+ Decorations",
				"1+ Exterior Trees Lit",
				"1+ Holiday Wreaths",
				"Custom-to-Home wiring",
				"Mechanical Timer",
				"All extension cords",
				"All clips",
				"Setup & Teardown",
				"A dedication to actively fix setups"
			]
		}

		return items;
	},

	getDetailedPackageItemsFor(isGround, theme) {
		let items;

		if (isGround) {
			if (theme === "Cool White") {
				items = [
					"4 Bushes with Cool White nets",
					"2 Decorations",
					"1 Standard Wreath"
				];
			} else if (theme === "Warm White") {
				items = [
					"4 Bushes with Warm White nets",
					"2 Decorations",
					"1 Standard Wreath"
				];
			} else { // multicolor
				items = [
					"4 Bushes with Multicolor nets",
					"2 Decorations",
					"1 Standard Wreath"
				];
			}
		} else {
			if (theme === "Cool White") {
				items = [
					"Entire gutterline with Cool White lights",
					"4 Bushes with Cool White nets",
					"2 Decorations",
					"1 Tree with Cool White mini string lights, trunk wrapped",
					"1 Standard Wreath"
				];
			} else if (theme === "Warm White") {
				items = [
					"Entire gutterline with Warm White lights",
					"4 Bushes with Warm White nets",
					"2 Decorations",
					"1 Tree with Warm White mini string lights, trunk wrapped",
					"1 Standard Wreath"
				];
			} else { // multicolor
				items = [
					"Entire gutterline with Multicolor lights",
					"4 Bushes with Multicolor nets",
					"2 Decorations",
					"1 Tree with Multicolor mini string lights, trunk wrapped",
					"1 Standard Wreath"
				];
			}
		}

		items.push(
			"Custom-to-Home wiring",
			"Mechanical Timer",
			"All extension cords",
			"All clips",
			"Setup & Teardown",
			"A dedication to actively fix setups"
		);

		return items;
	}
};
