import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from "./Button";
import {ScreenSize} from "../constants/screensize";

const Container = styled.div`
	background-color: white;
	border-top: 1px solid ${props => props.showborder ? 'grey' : 'white'};
	height: 100px;
	z-index: 500;
	position: fixed;
	bottom: 0;
	width: calc(100vw - ${props => props.minusAmount}px);
	
	@media (max-width: ${ScreenSize.tablet}px) {
		height: 80px;
		width: 100vw;
		padding-bottom: 20px;
	}
`;

const LabelButtonContainer = styled.div`
	display: flex;
	justify-content: ${props => props.showSaveButton ? 'space-between' : 'flex-end'};
	align-items: center;
	height: 100%;
	width: calc(100% - 100px);
	margin: auto;
	
	@media (max-width: ${ScreenSize.phone}px) {
		justify-content: center;
		width: 95%;
	}
`;

const NotMobile = styled.span`
	@media (max-width: ${ScreenSize.phone}px) {
		display: none;
	}
`;

const ButtonContainer = styled.div`
	width: ${props => props.small ? 200 : 250}px;
	
	@media (max-width: ${ScreenSize.phone}px) {
		margin-right: ${props => props.small ? 10 : 0}px;
		width: ${props => props.small ? 50 : 90}%;
		max-width: ${props => props.small ? 100 : 350}px;
	}
`;

class LowerNavigationSection extends Component {
	render() {

		const compareAmount = window.innerWidth < ScreenSize.laptop ? 350 : 500;
		const minusAmount = this.props.isFullscreen ? 0 : Math.min(window.innerWidth * 0.4, compareAmount) + 1;

		const labelAndButton = (
			<LabelButtonContainer showSaveButton={this.props.showSaveButton}>
				{this.props.showSaveButton &&
					<ButtonContainer small>
						<Button onClick={this.props.onSaveClicked}>
							Save<NotMobile> Progress</NotMobile>
						</Button>
					</ButtonContainer>
				}
				<ButtonContainer>
					<Button onClick={this.props.onNextClicked}>
						NEXT: {this.props.nextSectionName}
					</Button>
				</ButtonContainer>
			</LabelButtonContainer>
		);

		return (
			<Container minusAmount={minusAmount} showborder={!this.props.isLastSection}>
				{this.props.isLastSection ? null : labelAndButton}
			</Container>
		);
	}
}

LowerNavigationSection.propTypes = {
	nextSectionName: PropTypes.string.isRequired,
	isLastSection: PropTypes.bool.isRequired,
	isFullscreen: PropTypes.bool.isRequired,
	showSaveButton: PropTypes.bool.isRequired,
	onSaveClicked: PropTypes.func.isRequired,
	onNextClicked: PropTypes.func.isRequired
};

LowerNavigationSection.defaultProps = {
	isFullscreen: false
};

export default LowerNavigationSection;
