import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {BarLoader} from "react-spinners";
import Style from "../constants/style";

const Container = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background: ${Style.transparentBlack};
	z-index: 5000;
	color: white;
	font-size: 48px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
`;

const BarLoadingContainer = styled.div`
	margin-top: 50px;
	width: 100%;
`;

class LoadingOverlay extends Component {
	render() {
		return (
			<Container>
				<div>
					{this.props.message}
				</div>
				<BarLoadingContainer>
					<BarLoader
						css={{margin: 'auto'}}
						sizeUnit={"px"}
						height={7}
						width={250}
						color={"white"}
						loading={true}
					/>
				</BarLoadingContainer>
			</Container>
		);
	}
}

LoadingOverlay.propTypes = {
	message: PropTypes.string.isRequired
};

export default LoadingOverlay;
