import React, { Component } from 'react';
import styled from 'styled-components';
import Style from '../constants/style';
import momentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import { Utils } from '../utils/Utils';
import {Analytics} from "../utils/Analytics";
import {ScreenSize} from "../constants/screensize";
import RemoveButton from "./RemoveButton";
import OrderSummaryLineItem from "./OrderSummaryLineItem";

const OuterMargin = styled.div`
	margin: 0 30px;
`;

const Container = styled.div`
	max-width: 500px;
	margin: auto;
	border-radius: ${Style.borderRadius}px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	@media (max-width: ${ScreenSize.tablet}px) {
		max-width: initial;
	}
`;

const InlineContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
`;

const Header = styled.div`
	font-size: 20px;
	font-weight: 800;
	text-align: ${props => props.left ? 'left' : 'inherit'};

	@media (max-width: ${ScreenSize.laptop}px) {
		font-size: 18px;
	}

	@media (max-width: ${ScreenSize.phone}px) {
		font-size: 16px;
	}
`;

const InlineContentRight = styled.div`
	text-align: ${props => props.justify ? 'justify' : 'right'};
	padding-left: ${props => props.justify ? 15 : 0}px;
`;

const OrderSummaryHeader = styled(Header)`
	font-size: 30px;
	padding-bottom: 20px;

	@media (max-width: ${ScreenSize.laptop}px) {
		font-size: 24px;
	}

	@media (max-width: ${ScreenSize.phone}px) {
		font-size: 20px;
	}
`;

const SummaryContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 30px;
`;

const SectionContainer = styled.div`
	width: 100%;
`;

const SectionTitle = styled.div`
	border-top: 1px solid lightgray;
	padding: 10px 0;
	margin-top: 10px;
	text-align: left;
	font-size: larger;
	font-weight: 800;

	@media (max-width: ${ScreenSize.laptop}px) {
		font-size: 18px;
	}

	@media (max-width: ${ScreenSize.phone}px) {
		font-size: 16px;
	}
`;

const SectionLineItemContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	padding: 4px 30px 4px 4px;
`;

const SectionLineItemName = styled.div`
	text-align: left;
	white-space: ${props => props.notPre ? '' : 'pre'};

	@media (max-width: ${ScreenSize.laptop}px) {
		font-size: smaller;
	}
`;

const SectionLineItemPrice = styled.div`
	font-weight: ${props => props.included ? 'inherit' : 600};
	font-style: ${props => props.included ? 'italic' : 'inherit'};
	text-align: right;

	@media (max-width: ${ScreenSize.laptop}px) {
		font-size: smaller;
	}
`;

const TotalCost = styled.div`
	padding: 20px;
	font-size: 36px;
	font-weight: 800;
	color: darkgreen;
`;

const SendToTop = styled.div`
	width: 100%;
`;

const SendToBottom = styled.div`
	padding-top: 30px;
	width: 100%;
`;

class OrderSummary extends Component {
	render() {
		return (
			<OuterMargin>
				<Container>
					<SendToTop>
						<OrderSummaryHeader>
							ORDER SUMMARY
							<Header>
								Your personalized package includes:
							</Header>
						</OrderSummaryHeader>
						<SummaryContainer>
							{this.renderBasePrice()}
							{this.renderSectionData()}
							{this.renderExtraServices()}
						</SummaryContainer>
					</SendToTop>
					<SendToBottom>
						{this.props.onLastSection && (
							<InlineContainer>
								<Header left>
									Setup Address:
								</Header>
								<InlineContentRight>
									{this.props.address}
								</InlineContentRight>
							</InlineContainer>
						)}
						<InlineContainer>
							<Header left>
								Guaranteed
								<br />
								Setup Date:
							</Header>
							<InlineContentRight>
								{this.props.setupDate.format("dddd, MMMM Do, YYYY")}
							</InlineContentRight>
						</InlineContainer>
						<InlineContainer>
							<Header left>
								Guaranteed
								<br />
								Teardown Date:
							</Header>
							<InlineContentRight>
								{this.props.teardownDate.format("dddd, MMMM Do, YYYY")}
							</InlineContentRight>
						</InlineContainer>
						{this.props.onLastSection && (
							<InlineContainer>
								<Header left>
									Comments:
								</Header>
								<InlineContentRight justify>
									{this.props.comments}
								</InlineContentRight>
							</InlineContainer>
						)}
						{/*<InlineContainer>*/}
						{/*	<Header left>*/}
						{/*		Subtotal:*/}
						{/*	</Header>*/}
						{/*	<InlineContentRight>*/}
						{/*		{Utils.formatAsCurrency(this.props.subtotal)}*/}
						{/*	</InlineContentRight>*/}
						{/*</InlineContainer>*/}
						{/*<InlineContainer>*/}
						{/*	<Header left>*/}
						{/*		Tax:*/}
						{/*	</Header>*/}
						{/*	<InlineContentRight>*/}
						{/*		{Utils.formatAsCurrency(this.props.tax)}*/}
						{/*	</InlineContentRight>*/}
						{/*</InlineContainer>*/}
						<SendToBottom>
							<Header>
								Which brings your total
								<br/>
								for this season to:
							</Header>
							<TotalCost>
								{Utils.formatAsCurrency(this.props.total)}
							</TotalCost>
						</SendToBottom>
					</SendToBottom>
				</Container>
			</OuterMargin>
		);
	}

	renderBasePrice = () => {
		return (
			<SectionContainer>
				<SectionTitle>
					Holiday Package
				</SectionTitle>
				<OrderSummaryLineItem>
					<SectionLineItemContent>
						<SectionLineItemName>
							Base Price
						</SectionLineItemName>
						<SectionLineItemPrice>
							{Utils.formatAsCurrency(this.props.data.basePrice)}
						</SectionLineItemPrice>
					</SectionLineItemContent>
				</OrderSummaryLineItem>
			</SectionContainer>
		);
	};

	renderSectionData = () => {
		return this.props.data.sections.map((section, index) => {
			return section.type === "selector" || section.type === "builder" ? (
				<SectionContainer key={section.label + section.data}>
					<SectionTitle>
						{section.label}
					</SectionTitle>
					{section.type === "selector" ? this.renderSelectedItemsFor(section.categories) : this.renderAddedItemsFor(section, index)}
				</SectionContainer>
			) : null;
		});
	};

	renderSelectedItemsFor = (sectionCategories) => {
		const foundCategories = this.props.data.categories.filter(category => sectionCategories.includes(category.type));
		return foundCategories.map(category => {
			const selectedItem = category.items.filter(item => item.selected).shift();

			return selectedItem !== undefined ? (
				<OrderSummaryLineItem key={category.type}>
					<SectionLineItemContent>
						<SectionLineItemName>
							{selectedItem.name}
						</SectionLineItemName>
						<SectionLineItemPrice included={selectedItem.price === 0}>
							{selectedItem.price === 0 ? 'INCLUDED' : '+$' + selectedItem.price}
						</SectionLineItemPrice>
					</SectionLineItemContent>
					{selectedItem.removable ? (
						<RemoveButton onClick={() => this.removeSelectableItem(selectedItem.name, category.categoryName, category.type)} />
					) : null}
				</OrderSummaryLineItem>
			) : null;
		});
	};

	renderAddedItemsFor = (section, sectionIndex) => {
		let remainingAllowance = section.allowance;
		return section.addedItems.map((item, itemIndex) => {
			remainingAllowance -= item.price;
			return (
				<OrderSummaryLineItem key={itemIndex}>
					<SectionLineItemContent canRemove>
						<SectionLineItemName>
							{item.name}
						</SectionLineItemName>
						<SectionLineItemPrice included={remainingAllowance >= 0}>
							{remainingAllowance >= 0 ? 'INCLUDED' : '+$' + (Math.min(item.price, Math.abs(remainingAllowance)))}
						</SectionLineItemPrice>
					</SectionLineItemContent>
					<RemoveButton onClick={() => this.removeAddedItem(item.name, sectionIndex, itemIndex)}>
						<FontAwesomeIcon icon={faTimes}/>
					</RemoveButton>
				</OrderSummaryLineItem>
			);
		});
	};

	removeSelectableItem = (itemName, categoryName, categoryType) => {
		Analytics.logSelectableItemRemovedFromCart(categoryName, itemName.trim().replace(/\n/g, ", "));
		this.props.onRemoveClickedForSelectableItem(itemName, categoryType);
	};

	removeAddedItem = (itemName, sectionIndex, itemIndex) => {
		Analytics.logItemRemovedFromCart(itemName.trim().replace(/\n/g, ", "));
		this.props.onRemoveClickedForSectionItem(sectionIndex, itemIndex);
	};

	renderExtraServices() {
		const services = [
			"Lights and Decorations Setup",
			"Lights and Decorations Teardown",
			"Automatic On/Off Timer",
			"Custom-to-Home Wiring",
			"Guaranteed Fix-It Days",
			"Extension Cords",
			"Light Clips, Hooks, etc"
		];

		return (
			<SectionContainer>
				<SectionTitle>
					Additional Services
				</SectionTitle>
				{services.map(item => (
					<OrderSummaryLineItem key={item}>
						<SectionLineItemContent>
							<SectionLineItemName notPre>
								{item}
							</SectionLineItemName>
							<SectionLineItemPrice included>
								INCLUDED
							</SectionLineItemPrice>
						</SectionLineItemContent>
					</OrderSummaryLineItem>
				))}
			</SectionContainer>
		);
	}
}

OrderSummary.propTypes = {
	onLastSection: PropTypes.bool.isRequired,
	setupDate: momentPropTypes.momentObj,
	teardownDate: momentPropTypes.momentObj,
	subtotal: PropTypes.number.isRequired,
	tax: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	data: PropTypes.object.isRequired,
	comments: PropTypes.string.isRequired,
	address: PropTypes.string.isRequired,
	onRemoveClickedForSectionItem: PropTypes.func.isRequired,
	onRemoveClickedForSelectableItem: PropTypes.func.isRequired,
	onCommentsChanged: PropTypes.func.isRequired
};

export default OrderSummary;
