import React, {Component} from 'react';
import styled from 'styled-components';
import {ScreenSize} from "../constants/screensize";

const Container = styled.h3`
	width: 90%;
	margin: 20px auto;
	text-align: center;
	font-weight: 600;
		
	@media (max-width: ${ScreenSize.phone}px) {
		font-size: 16px;
	}
`;

class SectionSubHeader extends Component {
	render() {
		return (
			<Container>
				{this.props.children}
			</Container>
		);
	}
}

export default SectionSubHeader;
