import React, {Component} from 'react';
import styled from 'styled-components';
import store from 'store';
import WelcomeHeader from "./WelcomeHeader";
import {ImageBaseURL} from "../constants/server";
import {ScreenSize} from "../constants/screensize";
import Style from "../constants/style";
import {Analytics} from "../utils/Analytics";
import {PackageHelper} from "../utils/PackageHelper";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Container = styled.div`
	text-align: center;
	background-color: white;
	background-image: url(${ImageBaseURL + "/white_tree_bg.jpg"});
	background-size: cover;
	background-position: center;
	min-height: 100vh;
`;

const PackageDescription = styled.h5`
	margin: 0;
	font-weight: 200;
`;

const CenterContentContainer = styled.div`
	width: 90%;
	min-height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 28px;
	margin: 5vh auto auto;

	@media (max-width: ${ScreenSize.tablet}px) {
		font-size: 24px;
	}

	@media (max-width: ${ScreenSize.phone}px) {
		font-size: 20px;
		justify-content: flex-start;
		padding-top: ${props => props.returning ? 60 : 20}px;
	}
`;

const ButtonContainer = styled.div`
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding-bottom: 50vh;

	@media (max-width: ${ScreenSize.phone}px) {
		flex-direction: column;
		align-items: center;
	}
`;

const PackageName = styled.h3`
	margin: 0;
`;

const H4 = styled.h4`
	margin: 0;
`;

const PriceBlock = styled.div`
	padding: 50px 0 50px;
`;

const ThemeContainer = styled.div`
	width: 27vw;
	text-align: left;
	font-size: 0.7em;
	padding-bottom: 5vw;

	@media (max-width: ${ScreenSize.phone}px) {
		width: 80vw;
	}
`;

const ThemeButton = styled.div`
	border-radius: ${Style.borderRadius}px;
	border: 3px solid lightgrey;
	background: white;
	width: 100%;
	height: 27vw;
	display: flex;
	flex-direction: column;
	font-size: larger;

	@media (max-width: ${ScreenSize.phone}px) {
		width: 80vw;
		height: 80vw;
	}

	:hover {
		cursor: pointer;
		background: ${Style.primaryGreen};
	}

	:active {
		background: ${Style.primaryGreenHighlight};
	}
`;

const MiniText = styled.div`
	font-size: 0.5em;
`;

const TopPictureArea = styled.div`
	background: grey;
	width: 100%;
	flex: 8;
	overflow: hidden;
`;

const Image = styled.img`
	width: 100%;
	object-fit: cover;
`;

const BottomStrip = styled.div`
	font-weight: 600;
	width: 100%;
	flex: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

const BelowButtonContent = styled.div`
	width: 90%;
	margin: auto;

	@media (max-width: ${ScreenSize.phone}px) {
		font-size: larger;
	}
`;

const StrongAndLarger = styled.span`
	font-weight: 600;
	font-size: larger;
`;

const NoBulletList = styled.ul`
	list-style: none;
	margin-left: 0;
	padding-left: 0.5em;
`;

class PackageHomeSizePage extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
		Analytics.logCurrentURL();
	}

	render() {
		const items = [
			{size: "0-1999 sqft", price: 850, image: "1500.png"},
			{size: "2000-2999 sqft", price: 850, image: "2500.png"},
			{size: "3000+ sqft", price: 850, image: "3500.png"}
		];

		return (
			<Container>
				<WelcomeHeader>
					<PackageName>Full-Home Package</PackageName>
					<PackageDescription>For those who want their home to look stunning & full.</PackageDescription>
				</WelcomeHeader>
				<CenterContentContainer>
					<PackageName>
						What Size Home Are We Setting Up?
					</PackageName>
					<H4>Step 1 of 3</H4>
					<PriceBlock>
						$850 Flat
						<br/>
						<MiniText>
							Regardless of home size.
						</MiniText>
					</PriceBlock>
					<ButtonContainer>
						{items.map((item, index) => (
							<ThemeContainer key={item.size}>
								<ThemeButton onClick={() => this.onSizeClicked(index + 1, item.size)}>
									<TopPictureArea>
										<Image src={ImageBaseURL + "/" + item.image}/>
									</TopPictureArea>
									<BottomStrip>
										{item.size}
									</BottomStrip>
								</ThemeButton>
								<BelowButtonContent>
									<br/>
									For <StrongAndLarger>${item.price}</StrongAndLarger> you get:
									<NoBulletList>
										{PackageHelper.getFullHomeFullListPackageItems().map(item =>
											<li key={item}>
												<FontAwesomeIcon icon={faCheck} /> &nbsp; {item}
											</li>
										)}
									</NoBulletList>
								</BelowButtonContent>
							</ThemeContainer>
						))}
					</ButtonContainer>
				</CenterContentContainer>
			</Container>
		);
	}

	onSizeClicked = (sizeNumber, sizeName) => {
		store.set('homeSize', sizeNumber);
		Analytics.logPackageHomeSizePicked(sizeName);
		this.props.history.push('/fullhome/theme');
	};
}

PackageHomeSizePage.propTypes = {
};

PackageHomeSizePage.defaultProps = {
};

export default PackageHomeSizePage;
