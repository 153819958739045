import React, {Component} from 'react';
import styled from 'styled-components';
import {ScreenSize} from "../constants/screensize";

const Container = styled.h2`
	@media (max-width: ${ScreenSize.phone}px) {
		width: 90%;
		margin: 20px auto;
		font-size: 20px;
	}
`;

class SectionHeader extends Component {
	render() {
		return (
			<Container>
				{this.props.children}
			</Container>
		);
	}
}

export default SectionHeader;
