import React, {Component} from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	position: relative;
	
	:hover {
		background-color: lightgray;
		
		span {
			color: gray;
		}
	}
`;

class OrderSummaryLineItem extends Component {
	componentDidMount() {
		// console.log("MOUNTED");
	}

	componentWillUnmount() {
		// console.log("BYE");
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// console.log("UPDATED");
	}

	render() {
		return (
			<Container>
				{this.props.children}
			</Container>
		);
	}
}

OrderSummaryLineItem.propTypes = {};

export default OrderSummaryLineItem;
