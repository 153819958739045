import React, {Component} from 'react';
import styled from 'styled-components';
import CheckoutButton from "../components/CheckoutButton";
import TermsAndConditions from "../components/TermsAndConditions";
import DateContainer from "../components/DateContainer";
import moment from "moment";
import Button from "../components/Button";
import {FormCodes} from "../constants/errorcodes";
import {BarLoader} from 'react-spinners';
import Style from "../constants/style";
import WelcomeHeader from "../components/WelcomeHeader";
import {Utils} from "../utils/Utils";
import {Analytics} from "../utils/Analytics";
import LargeTextBox from "../components/LargeTextBox";
import AddressContainer from "../components/AddressContainer";
import store from 'store';
import {ScreenSize} from "../constants/screensize";
import {PackageHelper} from "../utils/PackageHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const OrderPageContainer = styled.div`
	text-align: center;
	background-color: white;
	min-height: 100vh;
`;

const CheckoutContainer = styled.div`
	padding-top: 50px;
	right: 0;
	width: ${props => props.fullscreen ? 100 : 30}vw;
	height: ${props => props.fullscreen ? '' : 'calc(100vh - 274px)'};
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: ${props => props.fullscreen ? '#F8F8F8' : 'white'};
	border-bottom: ${props => props.fullscreen ? 'none' : '1px solid gray'};
	z-index: ${props => props.fullscreen ? 1000 : 2000};
	padding-bottom: ${props => props.fullscreen ? '50' : '0'}vh;
`;

const MarginAuto = styled.div`
	width: 160px;
	padding: ${props => props.padding || 0}px;
	margin: auto;
`;

const LoadingOverlay = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background: ${Style.transparentBlack};
	z-index: 5000;
	color: white;
	font-size: 48px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
`;

const BarLoadingContainer = styled.div`
	margin-top: 50px;
	width: 100%;
`;

const Header = styled.div`
	font-size: ${props => props.large ? 40 : 20}px;
	font-weight: 800;
	text-align: center;
`;

const Options = styled.div`
	text-align: center;
	margin: auto;
	padding-top: 20px;
	font-size: 24px;

	@media (max-width: ${ScreenSize.tablet}px) {
		font-size: 16px;
	}
`;

const TotalCost = styled.div`
	padding: 20px;
	font-size: 36px;
	font-weight: 800;
	color: darkgreen;
	text-align: center;
`;

const PackageName = styled.h3`
	margin: 0;
`;

const PackageDescription = styled.h5`
	margin: 0;
	font-weight: 200;
`;

const LeftList = styled.ul`
	text-align: left;
	list-style: none;
	margin-left: 0;
	padding-left: 0.5em;
`;

class PackageReviewPage extends Component {
	constructor(props) {
		super(props);

		let theme = store.get('theme');
		if (theme === undefined) {
			theme = "Warm White";
		}

		let homeSize = store.get('homeSize');
		if (homeSize === undefined) {
			homeSize = 1;
		}

		let upsells = store.get('upsells');
		if (upsells === undefined) {
			upsells = [];
		}

		const setupDate = moment.max([moment().add(5, 'd'), moment('2021-11-14')]);
		const teardownDate = moment('2022-01-05');

		const isMobile = window.innerWidth <= ScreenSize.phone;
		const isTablet = window.innerWidth > ScreenSize.phone && window.innerWidth <= ScreenSize.tablet;
		const isLaptop = window.innerWidth > ScreenSize.tablet && window.innerWidth <= ScreenSize.laptop;

		this.state = {
			basePrice: this.calculateBasePrice(props.isGround, homeSize),
			termsAccepted: false,
			comments: "",
			setupAddress: "",
			setupDate: setupDate,
			teardownDate: teardownDate,
			submitting: false,
			showingError: false,
			isGround: props.isGround,
			homeSize: homeSize,
			theme: theme,
			isMobile: isMobile,
			isTablet: isTablet,
			isLaptop: isLaptop,
			included: PackageHelper.getDetailedPackageItemsFor(props.isGround, theme),
			upsells: upsells
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		Analytics.logCurrentURL();
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	// make sure to remove the listener
	// when the component is not mounted anymore
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		if (window.innerWidth <= ScreenSize.phone) {
			this.setState({isMobile: true, isTablet: false, isLaptop: false, width: window.innerWidth});
		} else if (window.innerWidth <= ScreenSize.tablet) {
			this.setState({isMobile: false, isTablet: true, isLaptop: false, width: window.innerWidth});
		} else if (window.innerWidth <= ScreenSize.laptop) {
			this.setState({isMobile: false, isTablet: false, isLaptop: true, width: window.innerWidth});
		} else if (window.innerWidth > ScreenSize.laptop) {
			this.setState({isMobile: false, isTablet: false, isLaptop: false, width: window.innerWidth});
		}
	};

	render() {
		const amountToAdd = this.state.upsells.filter(item => item.selected).reduce((total, item) => total + item.price, 0);
		const totalPrice = this.state.basePrice + amountToAdd;

		const formValidityCheck = this.isFormValid();

		const issueResolver = (
			<MarginAuto padding={40}>
				<Button onClick={() => this.resolveIssueFor(formValidityCheck)}>
					Resolve
				</Button>
			</MarginAuto>
		);

		const loadingOverlay = this.state.submitting ? (
			<LoadingOverlay>
				<div>
					Submitting, please wait.
				</div>
				<BarLoadingContainer>
					<BarLoader
						css={{margin: 'auto'}}
						sizeUnit={"px"}
						height={7}
						width={250}
						color={"white"}
						loading={true}
					/>
				</BarLoadingContainer>
			</LoadingOverlay>
		) : null;

		const packageDescription = this.state.isGround ?
			"For those who want to keep it simple, but still be festive." :
			"For those who want their home to look stunning & full.";

		const packageName = this.state.isGround ? "Ground-Only Package" : "Full-Home Package";

		return (
			<OrderPageContainer>
				{loadingOverlay}
				<WelcomeHeader>
					<PackageName>{packageName}</PackageName>
					<PackageDescription>{packageDescription}</PackageDescription>
				</WelcomeHeader>
				<CheckoutContainer fullscreen={true}>
					<Header large>
						Review Order & Checkout
					</Header>
					<Options>
						<Header>
							With selected options:
						</Header>
						Theme: {this.state.theme}
						<br/>
						Base Price: {Utils.formatAsCurrency(this.state.basePrice)}
						<br/>
						<br/>
						<LeftList>
							{this.state.upsells.map(item => (
								<li key={item.name}>
									<FontAwesomeIcon icon={faCheck} /> &nbsp; {item.selected ? item.name : item.alternative} ({item.selected ? "+$" + item.price : "INCLUDED"})
								</li>
							))}
							{this.state.included.map(item => (
								<li key={item}>
									<FontAwesomeIcon icon={faCheck} /> &nbsp; {item}
								</li>
							))}
						</LeftList>
					</Options>
					<Header>
						Which brings your total this year to:
					</Header>
					<TotalCost>
						{Utils.formatAsCurrency(totalPrice)}
					</TotalCost>
					<DateContainer numberOfDays={7} isMobile={this.state.isMobile} isLaptop={this.state.isLaptop} isTablet={this.state.isTablet} setupDate={this.state.setupDate} teardownDate={this.state.teardownDate} onSetupDateChanged={(date) => this.setState({setupDate: date})} onTeardownDateChanged={(date) => this.setState({teardownDate: date})} />
					<br/><br/>
					<AddressContainer tight autoFocus={false} value={this.state.setupAddress} onChange={this.handleSetupAddressChange}/>
					<LargeTextBox tight title="Additional Comments? (Optional)" placeholder="Do you have any additional comments or things that we should know about? Be as specific as you'd like. We don't like word limits." value={this.state.comments} onChange={this.onCommentsChanged} subtitle={""}/>
					<Header>
						Please re-read the Terms and Conditions, since they are different this year.
					</Header>
					<TermsAndConditions accepted={this.state.termsAccepted} onCheckChanged={(status) => this.setState({termsAccepted: status})}/>
					<CheckoutButton isPremadePackage={true} invalidReason={formValidityCheck.reason} enabled={formValidityCheck.valid} options={this.state} deposit={totalPrice/5} fullAmount={totalPrice} setupDate={this.state.setupDate} submittingStatusChanged={status => this.setState({submitting: status})}/>
					{!formValidityCheck.valid ? issueResolver : null}
				</CheckoutContainer>
			</OrderPageContainer>
		);
	}

	calculateBasePrice = (isGround) => {
		return isGround ? 500 : 850;
	};

	handleSetupAddressChange = setupAddress => {
		this.setState({ setupAddress });
	};

	onCommentsChanged = (newComments) => {
		this.setState({
			comments: newComments
		});
	};

	resolveIssueFor = ({code}) => {
		if (code === FormCodes.TERMS) {
			this.setState({termsAccepted: true});
		} else if (code === FormCodes.SETUP_ADDRESS) {

		}
	};

	isFormValid = () => {
		if (!this.state.termsAccepted) {
			return {valid: false, reason: "You must accept the Terms and Conditions before checking out.", code: FormCodes.TERMS};
		} else if (this.state.setupAddress.trim().length === 0) {
			return {valid: false, reason: "You must enter a Setup Address before checkout out.", code: FormCodes.SETUP_ADDRESS};
		}

		return {valid: true, reason: ""};
	};
}

PackageReviewPage.propTypes = {
};

PackageReviewPage.defaultProps = {
};

export default PackageReviewPage;
