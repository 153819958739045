import React, {Component} from 'react';
import styled from 'styled-components';
import WelcomeHeader from "./WelcomeHeader";
import {Analytics} from "../utils/Analytics";
import {ImageBaseURL} from "../constants/server";
import {ScreenSize} from "../constants/screensize";
import Style from "../constants/style";
import store from "store";
import Button from "./Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faCheck} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';

const Container = styled.div`
	text-align: center;
	background-color: white;
	background-image: url(${ImageBaseURL + "/white_tree_bg.jpg"});
	background-size: cover;
	background-position: center;
	min-height: 100vh;
`;

const CenterContentContainer = styled.div`
	width: 100%;
	min-height: ${props => 'calc(100vh - ' + (props.returning ? 100 : 174) + 'px)'};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 28px;
	margin: 5vh auto auto;

	@media (max-width: ${ScreenSize.tablet}px) {
		min-height: ${props => 'calc(100vh - ' + (props.returning ? 70 : 149) + 'px)'};
		font-size: 24px;
	}

	@media (max-width: ${ScreenSize.phone}px) {
		min-height: ${props => 'calc(100vh - ' + (props.returning ? 100 : 149) + 'px)'};
		font-size: 20px;
		justify-content: flex-start;
		padding-top: ${props => props.returning ? 60 : 20}px;
	}
`;

const ButtonContainer = styled.div`
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;

	@media (max-width: ${ScreenSize.phone}px) {
		flex-direction: column;
		align-items: center;
	}
`;

const PackageDescription = styled.h5`
	margin: 0;
	font-weight: 200;
`;

const PackageName = styled.h3`
	margin: 0;
`;

const H4 = styled.h4`
	margin: 0;
`;

const MiniH4 = styled.h4`
	margin: 0 0 50px;
	font-weight: 400;
	font-size: smaller;
`;

const UpsellContainer = styled.div`
	display: flex;
	width: 90%;
	max-width: 1000px;
	justify-content: space-between;
	align-items: flex-start;
	margin: auto;
`;

const ThemeContainer = styled.div`
	width: 40%;
	text-align: left;
	font-size: 0.7em;
	padding-bottom: 5vw;
`;

const ThemeButton = styled.div`
	border-radius: ${Style.borderRadius}px;
	border: 3px solid ${props => props.selected ? Style.darkGreen : 'lightgrey'};
	background: ${props => props.selected ? Style.primaryGreen : 'white'};
	width: 100%;
	height: 400px;
	display: flex;
	flex-direction: column;
	font-size: larger;

	:hover {
		cursor: pointer;
		background: ${Style.primaryGreen};
	}

	:active {
		background: ${Style.primaryGreenHighlight};
	}

	@media (max-width: ${ScreenSize.tablet}px) {
		height: 50vw;
	}

	@media (max-width: ${ScreenSize.phone}px) {
		height: 60vw;
	}
`;

const TopPictureArea = styled.div`
	background: grey;
	width: 100%;
	flex: 8;
	overflow: hidden;
`;

const Image = styled.img`
	width: 100%;
	object-fit: cover;
`;

const BottomStrip = styled.div`
	font-weight: 600;
	width: 100%;
	flex: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
`;

const MiniText = styled.div`
	font-size: smaller;
	color: ${props => props.grey ? 'grey' : Style.darkGreen};
	font-style: ${props => props.grey ? 'italic' : 'initial'};
`;

const BelowButtonContent = styled.div`
	width: 90%;
	margin: auto;

	@media (max-width: ${ScreenSize.phone}px) {
		font-size: larger;
	}
`;

const StrongAndLarger = styled.span`
	font-weight: 600;
	font-size: larger;
`;

const NoBulletList = styled.ul`
	list-style: none;
	margin-left: 0;
	padding-left: 0.5em;
`;

const BelowUpsellsContainer = styled.div`
	width: 100%;
	background: white;
	padding-top: 50px;
	padding-bottom: 100px;
	border-top: 1px solid gray;
`;

const NextButtonWrapper = styled.div`
	width: 90%;
	max-width: 400px;
	margin: 50px auto auto;
`;

class PackageUpsellPage extends Component {
	constructor(props) {
		super(props);

		Analytics.logCurrentURL();

		let homeSize = store.get('homeSize');
		if (homeSize === undefined) {
			homeSize = 1;
		}

		const rooflinePrice = 150 + ((homeSize - 1) * 50);
		let upsells = props.isGround ?
			[
				{
					name: "1 Tree (Trunk-Wrapped)",
					price: 70,
					selected: false,
					upsellImage: "tree/trunk.jpg",
					upsellBenefits: [
						"1 Tree lit up with Trunk Wrap",
						"Mini String LED in the color theme you chose",
						"A very clean and classy holiday vibe",
					],
					alternative: "No Trees",
					alternativeImage: "tree/trunk-none.jpg",
					normalBenefits: [
						"No trees will be lit up",
					]
				}
			]
			:
			[
				{
					name: "C9 LED (Old-Fashioned Bulbs)",
					price: 225,
					selected: false,
					upsellImage: "packages/c9.jpg",
					upsellBenefits: [
						"Large C9 LED bulbs in the theme you chose",
						"Equally-spaced (~1 ft apart)",
						"They look like perfect dots from a distance",
						"A very clean and classy holiday vibe",
						"Custom-measured to match your home",
					],
					alternative: "Mini String LED",
					alternativeImage: "packages/mini.jpg",
					normalBenefits: [
						"Typical mini-string LED lights in the theme you chose",
						"A nice trim to compliment the home",
						"Custom-measured to match your home",
					]
				},
				{
					name: "Roofline & Peaks",
					price: rooflinePrice,
					selected: false,
					upsellImage: "roofyes.jpg",
					upsellBenefits: [
						"One of our offerings we are MOST proud of",
						"This will make your home look stunning from a distance and will make it stand out this season",
						"The top line / peaks of the roof will have lights",
						"Satisfaction knowing that your home looks stunning from all angles"
					],
					alternative: "Just Gutterline",
					alternativeImage: "roofno.jpg",
					normalBenefits: [
						"The gutterline lit up",
						"Any area that doesn't have a \"gutter\", but is front-facing (eg: above a garage or porch)",
					]
				}
			];

		let selectedUpsells = store.get('selectedUpsells');
		if (selectedUpsells !== undefined) {
			let names = selectedUpsells.map(upsell => upsell.name);
			upsells = upsells.map(item => {
				if (names.includes(item.name)) {
					item.selected = true;
				}
				return item;
			});
		}

		this.state = {
			upsells: upsells,
			homeSize: homeSize
		}
	};

	componentDidMount() {
		window.scrollTo(0,0);
	}

	render() {
		const amountToAdd = this.state.upsells.filter(item => item.selected).reduce((total, item) => total + item.price, 0);
		let totalPrice = (this.props.isGround ? 500 : 850) + amountToAdd;

		const packageDescription = this.props.isGround ?
			"For those who want to keep it simple, but still be festive." :
			"For those who want their home to look stunning & full.";

		const packageName = this.props.isGround ? "Ground-Only Package" : "Full-Home Package";

		const step = this.props.isGround ?
			"Step 2 of 2" :
			"Step 3 of 3";

		return (
			<Container>
				<WelcomeHeader>
					<PackageName>{packageName}</PackageName>
					<PackageDescription>{packageDescription}</PackageDescription>
				</WelcomeHeader>
				<CenterContentContainer>
					<PackageName>
						Make Your Home Extra-Beautiful With These Options
					</PackageName>
					<H4>{step}</H4>
					<MiniH4>Your friends, family, and neighbors will be in awe.</MiniH4>
					<MiniH4>
						{!this.props.isGround ? <div>
							NOTE: These options are for the home itself and do not affect trees or bushes.
							<br/>
							</div>
							: null
						}
						<StrongAndLarger>Scroll down to bottom of page and click "Review Order" to continue.</StrongAndLarger>
					</MiniH4>
					<ButtonContainer>
						{this.state.upsells.map((item, index) => (
							<UpsellContainer key={item.name}>
								<ThemeContainer>
									<ThemeButton onClick={() => this.onUpsellSelected(index, false)} selected={!item.selected}>
										<TopPictureArea>
											<Image src={ImageBaseURL + "/" + item.alternativeImage}/>
										</TopPictureArea>
										<BottomStrip>{!item.selected ?
											<MiniText grey>
												SELECTED
											</MiniText>
											: null}
											{item.alternative}
											<MiniText>
												Included
											</MiniText>
										</BottomStrip>
									</ThemeButton>
									<BelowButtonContent>
										<br/>
										Included <StrongAndLarger></StrongAndLarger> you get:
										<NoBulletList>
											{item.normalBenefits.map(benefit => (
												<li key={benefit}>
													<FontAwesomeIcon icon={faCheck}/> &nbsp; {benefit}
												</li>
											))}
										</NoBulletList>
									</BelowButtonContent>
								</ThemeContainer>
								<ThemeContainer key={item.name}>
									<ThemeButton onClick={() => this.onUpsellSelected(index, true)} selected={item.selected}>
										<TopPictureArea>
											<Image src={ImageBaseURL + "/" + item.upsellImage}/>
										</TopPictureArea>
										<BottomStrip>
											{item.selected ?
												<MiniText grey>
													SELECTED
												</MiniText>
												: null}
											{item.name}
											<MiniText>
												+${item.price}
											</MiniText>
										</BottomStrip>
									</ThemeButton>
									<BelowButtonContent>
										<br/>
										For <StrongAndLarger>${item.price}</StrongAndLarger> you get:
										<NoBulletList>
											{item.upsellBenefits.map(benefit => (
												<li key={benefit}>
													<FontAwesomeIcon icon={faCheck}/> &nbsp; {benefit}
												</li>
											))}
										</NoBulletList>
									</BelowButtonContent>
								</ThemeContainer>
							</UpsellContainer>
						))}
						<BelowUpsellsContainer>
							<NextButtonWrapper>
								<PackageName>
									That brings your total to:
								</PackageName>
								<PackageName>
									${totalPrice}
								</PackageName>
								<br/>
								<br/>
								<Button onClick={this.onReviewOrderClicked}>
									Review Order &nbsp; <FontAwesomeIcon icon={faArrowRight} />
								</Button>
							</NextButtonWrapper>
						</BelowUpsellsContainer>
					</ButtonContainer>
				</CenterContentContainer>
			</Container>
		);
	}

	onUpsellSelected = (index, yesOrNo) => {
		this.setState(state => {
			let upsells = state.upsells;

			upsells[index].selected = yesOrNo;

			const selectedUpsells = upsells.filter(item => item.selected).map(item => ({
				name: item.name,
				price: item.price
			}));

			store.set('selectedUpsells', selectedUpsells);

			Analytics.logPackageUpsellSelected(upsells[index].name);

			return {
				upsells: upsells
			};
		});
	};

	onReviewOrderClicked = () => {
		store.set('upsells', this.state.upsells);
		const route = this.props.isGround ? '/ground/review' : '/fullhome/review';
		this.props.history.push(route);
	};
}

PackageUpsellPage.propTypes = {
	isGround: PropTypes.bool.isRequired
};

export default PackageUpsellPage;
