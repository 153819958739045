import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import Style from "../constants/style";
import SectionHeader from "./SectionHeader";
import SectionSubHeader from "./SectionSubHeader";
import {ScreenSize} from "../constants/screensize";

const Container = styled.div`
	width: 90%;
	max-width: 700px;
	margin: 0 auto ${props => props.tight ? 100 : 400}px auto;
	padding-top: 20px;
	text-align: center;
`;

const Input = styled.input`
	margin-top: 40px;
	border: 2px solid grey;
	display: block;
	width: calc(100% - 33px);
	padding: 16px;
	font-size: 24px;
	border-radius: ${Style.borderRadius}px;
	outline: none;
	
	@media (max-width: ${ScreenSize.phone}px) {
		font-size: 18px;
	}
`;

const SuggestionContainer = styled.div`
	width: 100%;
	margin-top: 10px;
	text-align: left;
`;

const Suggestion = styled.div`
	width: calc(100% - 33px);
	padding: 8px 16px;
	background: ${props => props.active ? Style.primaryGreen : 'white'};
	cursor: ${props => props.active ? 'pointer' : 'initial'};
	font-weight: ${props => props.active ? 600 : 400};
	font-size: 20px;
	border-bottom: 1px solid grey;
	
	:last-child {
		border-bottom: none;
	}
	
	@media (max-width: ${ScreenSize.phone}px) {
		font-size: 16px;
	}
`;

class AddressContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			placesLoaded: false
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.checkForGoogle(0);
		}, 10);
	}

	checkForGoogle = (count) => {
		if (window.google) {
			this.setState({placesLoaded: true});
		} else if (count < 100) {
			setTimeout(() => {
				this.checkForGoogle(count + 1);
			}, 100);
		}
	};

	render() {
		return (
			<Container tight={this.props.tight}>
				<SectionHeader>
					What address are we setting this up at?
				</SectionHeader>
				<SectionSubHeader>
					We just want to show up to the right place. It might be that you're ordering for someone else, possibly as a gift, so we have to ask to be sure.
					<br />
					<br />
					Note that this does not have to be the same as the billing address.
				</SectionSubHeader>
				{this.state.placesLoaded ?
					<PlacesAutocomplete
						value={this.props.value}
						onChange={this.props.onChange}
						shouldFetchSuggestions={this.props.value.length > 1}
					>
						{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
							<div>
								<Input autoFocus={this.props.autoFocus} {...getInputProps({
									placeholder: 'Start typing setup address...'
								})}/>

								<SuggestionContainer>
									{loading && <div>Loading...</div>}
									{suggestions.map(suggestion => {
										return (
											<Suggestion {...getSuggestionItemProps(suggestion)} active={suggestion.active}>
												<span>{suggestion.description}</span>
											</Suggestion>
										);
									})}
								</SuggestionContainer>
							</div>
						)}
					</PlacesAutocomplete> :
					<Input value={this.props.value} onChange={(e) => this.props.onChange(e.target.value)} placeholder={'Type your address'} />
				}
			</Container>
		);
	}
}

AddressContainer.propTypes = {
	value: PropTypes.string.isRequired,
	autoFocus: PropTypes.bool.isRequired,
	tight: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired
};

AddressContainer.defaultProps = {
	tight: false,
	autoFocus: true
};

export default AddressContainer;
