import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from "moment";
import SingleDatePickerWrapper from "./Calendar/SingleDatePickerWrapper";
import CalendarPhrases from "./Calendar/calendarPhrases";
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import momentPropTypes from 'react-moment-proptypes';
import Style from "../constants/style";
import {Analytics} from "../utils/Analytics";
import {ScreenSize} from "../constants/screensize";
import Button from "./Button";
import SectionHeader from "./SectionHeader";
import SectionSubHeader from "./SectionSubHeader";


const MarginContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 40px;
	text-align: center;
	max-width: 900px;
	margin: auto;

	:last-child {
		border-bottom: none;
	}

	@media (max-width: ${ScreenSize.phone}px) {
		padding-bottom: 120px;
	}
`;

const Container = styled.div`
	max-width: 700px;
	padding-top: 30px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: auto;
	flex-direction: row;

	@media (max-width: ${ScreenSize.phone}px) {
		flex-direction: column;
	}
`;

const CalendarContainer = styled.div`
	width: 318px;
	height: ${props => props.lookingAtCalendar ? '480' : '200'}px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	z-index: ${props => props.z};
`;

const CalendarTitle = styled.div`
	font-size: 24px;
	padding-bottom: 30px;
	color: ${Style.darkGreen};

	@media (min-width: ${ScreenSize.phone+1}px) and (max-width: ${ScreenSize.laptop}px) {
		font-size: 20px;
	}

	@media (max-width: ${ScreenSize.phone}px) {
		padding-bottom: 10px;
	}
`;

class DateContainer extends Component {
	constructor(props) {
		super(props);

		const earliestDate = moment.max([moment().add(props.numberOfDays, 'd'), moment('2023-11-01')]);

		this.state = {
			earliestPossibleSetupDate: earliestDate,
			earliestPossibleTeardownDate: moment('2024-01-03'),
			lastPossibleSetupDate: moment('2023-12-18'),
			lastPossibleTeardownDate: moment('2024-01-31'),
			lookingAtSetup: false,
			lookingAtTeardown: false,
			blockedDays: [
				moment('2023-12-16').format('YYYY-MM-DD'),
				moment('2023-12-15').format('YYYY-MM-DD'),
				moment('2023-12-08').format('YYYY-MM-DD'),
				moment('2023-12-01').format('YYYY-MM-DD'),
				moment('2023-11-24').format('YYYY-MM-DD'),
				moment('2023-11-23').format('YYYY-MM-DD'),
				moment('2023-11-03').format('YYYY-MM-DD'),
				moment('2023-11-10').format('YYYY-MM-DD'),
				moment('2023-11-17').format('YYYY-MM-DD'),
				moment('2023-01-20').format('YYYY-MM-DD'),
				moment('2023-11-21').format('YYYY-MM-DD'),
				moment('2023-11-18').format('YYYY-MM-DD'),
				moment('2024-01-08').format('YYYY-MM-DD'),
				moment('2024-01-07').format('YYYY-MM-DD'),
				moment('2024-01-09').format('YYYY-MM-DD'),
				moment('2024-01-10').format('YYYY-MM-DD'),
				moment('2024-01-11').format('YYYY-MM-DD'),
				moment('2024-01-12').format('YYYY-MM-DD'),
				moment('2024-01-13').format('YYYY-MM-DD'),
				moment('2024-01-14').format('YYYY-MM-DD'),
				moment('2024-01-15').format('YYYY-MM-DD'),
				moment('2024-01-17').format('YYYY-MM-DD'),
			]
		}
	}

	setupDateChanged = (date) => {
		const dateAsString = date.format('YYYY-MM-DD');
		Analytics.logDateSelected(true, dateAsString);
		this.setState({
			lookingAtSetup: false
		});
		this.props.onSetupDateChanged(date);
	};

	teardownDateChanged = (date) => {
		const dateAsString = date.format('YYYY-MM-DD');
		Analytics.logDateSelected(false, dateAsString);
		this.setState({
			lookingAtTeardown: false
		});
		this.props.onTeardownDateChanged(date);
	};

	render() {
		const daySize = this.props.isTablet || this.props.isLaptop ? 30 : 39;

		return (
			<MarginContainer>

				<SectionHeader>
					When would you like us to come out?
				</SectionHeader>
				<SectionSubHeader>
					Choose the setup and teardown date. It is possible we will come out prior to selected date.
				</SectionSubHeader>
				<Container>
					<CalendarContainer z={2} lookingAtCalendar={this.props.isMobile ? this.state.lookingAtSetup : true}>
						<CalendarTitle>Guaranteed Setup Date:</CalendarTitle>
						<SingleDatePickerWrapper
							id="startdate"
							date={this.props.setupDate}
							isDayBlocked={day => this.state.blockedDays.includes(day.format('YYYY-MM-DD'))}
							isDayHighlighted={function noRefCheck() {}}
							onNextMonthClick={function noRefCheck() {}}
							onPrevMonthClick={function noRefCheck() {}}
							onDateChange={this.setupDateChanged}
							isOutsideRange={day => !isInclusivelyAfterDay(day, this.state.earliestPossibleSetupDate) || !isInclusivelyBeforeDay(day, this.state.lastPossibleSetupDate)}
							phrases={CalendarPhrases}
							placeholder="Start Date"
							daySize={daySize}
							focused={this.props.isMobile ? this.state.lookingAtSetup : true}
						/>
						{this.props.isMobile && !this.state.lookingAtSetup ? (
							<Button onClick={() => this.setState({lookingAtSetup: true})}>
								Choose Setup Date
							</Button>
						) : null}
					</CalendarContainer>
					<CalendarContainer z={1} lookingAtCalendar={this.props.isMobile ? this.state.lookingAtTeardown : true}>
						<CalendarTitle>Guaranteed Teardown Date:</CalendarTitle>
						<SingleDatePickerWrapper
							id="teardowndate"
							date={this.props.teardownDate}
							isDayBlocked={day => this.state.blockedDays.includes(day.format('YYYY-MM-DD'))}
							isDayHighlighted={function noRefCheck() {}}
							onNextMonthClick={function noRefCheck() {}}
							onPrevMonthClick={function noRefCheck() {}}
							onDateChange={this.teardownDateChanged}
							isOutsideRange={day => !isInclusivelyAfterDay(day, this.state.earliestPossibleTeardownDate) || !isInclusivelyBeforeDay(day, this.state.lastPossibleTeardownDate)}
							phrases={CalendarPhrases}
							placeholder="Teardown Date"
							daySize={daySize}
							focused={this.props.isMobile ? this.state.lookingAtTeardown : true}
						/>
						{this.props.isMobile && !this.state.lookingAtTeardown ? (
							<Button onClick={() => this.setState({lookingAtTeardown: true})}>
								Choose Teardown Date
							</Button>
						) : null}
					</CalendarContainer>
				</Container>
				<SectionSubHeader>
					We do not need you to be present/home during setup or teardown, but feel free to choose a date that works best for you.
				</SectionSubHeader>
			</MarginContainer>
		);
	}
}

DateContainer.propTypes = {
	numberOfDays: PropTypes.number.isRequired,
	isMobile: PropTypes.bool.isRequired,
	isTablet: PropTypes.bool.isRequired,
	isLaptop: PropTypes.bool.isRequired,
	setupDate: momentPropTypes.momentObj,
	teardownDate: momentPropTypes.momentObj,
	onSetupDateChanged: PropTypes.func.isRequired,
	onTeardownDateChanged: PropTypes.func.isRequired
};

export default DateContainer;
