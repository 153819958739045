import React, {Component} from 'react';
import styled from 'styled-components';
import store from 'store';
import moment from "moment";
import ReactNotification from 'react-notifications-component'
import { store as NotificationCenter } from 'react-notifications-component';
import OrderSummary from './components/OrderSummary';
import CategoryContainer from './components/CategoryContainer';
import SectionNavigator from "./components/SectionNavigator";
import LowerNavigationSection from "./components/LowerNavigationSection";
import CheckoutButton from "./components/CheckoutButton";
import TermsAndConditions from "./components/TermsAndConditions";
import DateContainer from "./components/DateContainer";
import Button from "./components/Button";
import AddressContainer from "./components/AddressContainer";
import LargeTextBox from "./components/LargeTextBox";
import {ImageBaseURL} from "./constants/server";
import {FormCodes} from "./constants/errorcodes";
import {ScreenSize} from "./constants/screensize";
import {Analytics} from "./utils/Analytics";
import preloadedData from './data';
import versionNumber from './version';
import LoadingOverlay from "./components/LoadingOverlay";
import 'react-notifications-component/dist/theme.css';
import SuccessNotification from "./components/SuccessNotification";

const OrderPageContainer = styled.div`
`;

const CenterContentContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100vw;
	height: ${props => props.fullscreen ? '' : 'calc(100vh - 174px)'};

	@media (max-width: ${ScreenSize.tablet}px) {
		flex-direction: column;
		height: ${props => props.fullscreen ? '' : 'calc(100vh - 149px)'};
	}

	@media (max-width: ${ScreenSize.phone}px) {
		height: ${props => props.fullscreen ? '' : 'calc(100vh - 154px)'};
	}
`;

const LeftSideContainer = styled.div`
	background-color: #F8F8F8;
	color: #464646;
	overflow: scroll;
	flex: ${props => props.fullscreen ? 0 : 6};
	//background-image: url(${ImageBaseURL + "/white_tree_bg.jpg"});
	//background-size: cover;
	//background-position: center;

	@media (max-width: ${ScreenSize.phone}px) {
		overflow: visible;
	}
`;

const ContentContainer = styled.div`
	text-align: center;
	max-width: 900px;
	position: relative;
	margin: auto auto 200px;
`;

const CheckoutContainer = styled.div`
	max-width: ${props => props.fullscreen ? '100vw' : '500px'};
	background: ${props => props.fullscreen ? '#F8F8F8' : 'white'};
	border-left: ${props => props.fullscreen ? 0 : 1}px solid gray;
	padding: 30px 0 ${props => props.fullscreen ? '30vh' : '0'} 0;
	overflow-y: scroll;
	flex: 4;
	height: ${props => props.fullscreen ? 'inherit' : 'initial'};

	@media (max-width: ${ScreenSize.laptop}px) {
		max-width: ${props => props.fullscreen ? '100vw' : '350px'};
	}

	@media (max-width: ${ScreenSize.tablet}px) {
		display: ${props => props.fullscreen ? '' : 'none'};
	}

	// IN ORDER TO ADD A RECEIPT JAG, JUST UNCOMMENT THIS:
	//:after {
	//	content: "";
	//	display: block;
	//	position: relative;
	//	top: 0;
	//	left: 0;
	//	width: 100%;
	//	height: 60px;
	//	background: linear-gradient(#ffffff 0%, transparent 0%), linear-gradient(135deg, #D2D2D2 33.33%, transparent 33.33%) 0 0, #D2D2D2 linear-gradient(45deg, #D2D2D2 33.33%, #ffffff 33.33%) 0 0;
	//
	//	background: -webkit-linear-gradient(#ffffff 0%, transparent 0%), -webkit-linear-gradient(135deg, #D2D2D2 33.33%, transparent 33.33%) 0 0, #D2D2D2 -webkit-linear-gradient(45deg, #D2D2D2 33.33%, #ffffff 33.33%) 0 0;
	//
	//	background: -o-linear-gradient(#ffffff 0%, transparent 0%), -o-linear-gradient(135deg, #D2D2D2 33.33%, transparent 33.33%) 0 0, #D2D2D2 -o-linear-gradient(45deg, #D2D2D2 33.33%, #ffffff 33.33%) 0 0;
	//
	//	background: -moz-linear-gradient(#ffffff 0%, transparent 0%), -moz-linear-gradient(135deg, #D2D2D2 33.33%, transparent 33.33%) 0 0, #D2D2D2 -moz-linear-gradient(45deg, #D2D2D2 33.33%, #ffffff 33.33%) 0 0;
	//
	//	background-repeat: repeat-x;
	//	background-size: 40px 40px;
	//}
`;

const MarginAuto = styled.div`
	width: 160px;
	padding: ${props => props.padding || 0}px;
	margin: auto;
`;

export default class App extends Component {
	constructor(props) {
		super(props);
		this.leftSideContainer = React.createRef();

		const isMobile = window.innerWidth <= ScreenSize.phone;
		const isTablet = window.innerWidth > ScreenSize.phone && window.innerWidth <= ScreenSize.tablet;
		const isLaptop = window.innerWidth > ScreenSize.tablet && window.innerWidth <= ScreenSize.laptop;

		this.earliestNumberOfDays = 7;
		const earliestDate = moment.max([moment().add(this.earliestNumberOfDays, 'd'), moment('2022-12-07')]);

		let location = "";
		if (props.location) {
			location = props.location.pathname.substr(1);
		}
		location = location === "" ? "homesize" : location;
		Analytics.logPageView(location);

		let localStorage = store.get('state');

		if (localStorage !== undefined && localStorage.version === versionNumber.version) {
			localStorage.setupDate = earliestDate;
			localStorage.teardownDate = moment(localStorage.teardownDate);
			localStorage.selectedSection = this.findSectionBasedOnURL(location, localStorage.data);
			localStorage.isBuilder = localStorage.data.sections[localStorage.selectedSection].type === "builder";

			this.state = localStorage;
		} else {
			const data = this.preloadedDataWithConvertedPrices();
			const selectedSection = this.findSectionBasedOnURL(location, data);
			const isBuilder = data.sections[selectedSection].type === "builder";

			this.state = {
				data: data,
				selectedSection: selectedSection,
				isBuilder: isBuilder,
				termsAccepted: false,
				setupAddress: '',
				setupDate: earliestDate,
				teardownDate: moment('2023-01-04'),
				comments: "",
				submitting: false,
				width: window.innerWidth,
				isMobile: isMobile,
				isTablet: isTablet,
				isLaptop: isLaptop,
				version: versionNumber.version
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.history.action === "POP") {
			const selectedSection = this.findSectionBasedOnURL(this.props.location.pathname.substr(1), prevState.data);
			this.changeToSectionAtIndex(selectedSection, true);
		}
	}

	componentDidMount() {
		window.scrollTo(0,0);
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	// make sure to remove the listener
	// when the component is not mounted anymore
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		if (window.innerWidth <= ScreenSize.phone) {
			this.setState({isMobile: true, isTablet: false, isLaptop: false, width: window.innerWidth});
		} else if (window.innerWidth <= ScreenSize.tablet) {
			this.setState({isMobile: false, isTablet: true, isLaptop: false, width: window.innerWidth});
		} else if (window.innerWidth <= ScreenSize.laptop) {
			this.setState({isMobile: false, isTablet: false, isLaptop: true, width: window.innerWidth});
		} else if (window.innerWidth > ScreenSize.laptop) {
			this.setState({isMobile: false, isTablet: false, isLaptop: false, width: window.innerWidth});
		}
	};

	findSectionBasedOnURL = (location, data) => {
		return Math.max(0, data.sections.map(section => section.data).indexOf(location));
	};

	preloadedDataWithConvertedPrices = () => {
		let newData = preloadedData;

		newData.sections = newData.sections.map(section => {
			if (section.type !== "builder") {
				return section;
			}

			let newSectionCurrentItem;

			const categoriesToIncludes = newData.categories.filter(category => {
				return section.categories.includes(category.type)
			});

			newSectionCurrentItem = categoriesToIncludes.map(category => {
				return {...category, "name": ""}
			});

			section.currentItem = newSectionCurrentItem;
			return section;
		});

		return newData;
	};

	render() {
		store.set('state', this.state);
		const subtotal = this.calculateTotalPrice();
		const tax = Math.ceil(subtotal * 0.07);
		const totalPrice = subtotal;

		const selectedSection = this.state.data.sections[this.state.selectedSection];
		const onLastSection = this.state.selectedSection === this.state.data.sections.length - 1;
		const onDateSection = selectedSection.data === "dates";
		const onAddressSection = selectedSection.data === "address";
		const onCommentsSection = selectedSection.data === "comments";
		const nextSectionName = !onLastSection ? this.state.data.sections[this.state.selectedSection + 1].label : "";
		const lowerSection = onLastSection ? null : <LowerNavigationSection showSaveButton={true} onSaveClicked={this.onSaveClicked} onNextClicked={this.nextSectionClicked} nextSectionName={nextSectionName} isLastSection={onLastSection}/>;

		const formValidityCheck = this.isFormValid();
		const checkoutButton = <CheckoutButton invalidReason={formValidityCheck.reason} enabled={formValidityCheck.valid} options={this.state} deposit={Math.ceil(totalPrice/5)} fullAmount={totalPrice} setupDate={this.state.setupDate} submittingStatusChanged={status => this.setState({submitting: status})}/>;
		const tAndC = <TermsAndConditions accepted={this.state.termsAccepted} onCheckChanged={(status) => this.setState({termsAccepted: status})}/>;
		const setupAddressSection = <AddressContainer onChange={this.handleSetupAddressChange} value={this.state.setupAddress}/>;
		const commentsSection = <LargeTextBox title="Additional Comments? (Optional)" subtitle="Do you have any additional comments or things that we should know about? Anything we missed or forgot? Be as specific as you'd like. We don't like word limits." placeholder="Comments/things to know..." value={this.state.comments} onChange={this.onCommentsChanged}/>;
		const issueResolver = <MarginAuto padding={40}><Button onClick={() => this.resolveIssueFor(formValidityCheck)}>Resolve</Button></MarginAuto>;
		const dateSection = (
			<DateContainer
				numberOfDays={this.earliestNumberOfDays}
				isMobile={this.state.isMobile}
				isTablet={this.state.isTablet}
				isLaptop={this.state.isLaptop}
				setupDate={this.state.setupDate}
				teardownDate={this.state.teardownDate}
				onSetupDateChanged={(date) => this.setState({setupDate: date})}
				onTeardownDateChanged={(date) => this.setState({teardownDate: date})} />
		);


		return (
			<OrderPageContainer>
				<ReactNotification breakpoint={350} />
				{this.state.submitting && <LoadingOverlay message="Submitting, please wait."/>}
				<SectionNavigator isMobile={this.state.isMobile} sections={this.state.data.sections} selectedSection={this.state.selectedSection} onSectionClicked={this.changeToSectionAtIndex}/>
				<CenterContentContainer fullscreen={onLastSection}>
					<LeftSideContainer ref={this.leftSideContainer} fullscreen={onLastSection}>
						{onLastSection ? null :
							onAddressSection ? setupAddressSection :
							onCommentsSection ? commentsSection :
							onDateSection ? dateSection :
								(
									<ContentContainer>
										<CategoryContainer
											isMobile={this.state.isMobile}
											section={selectedSection}
											allCategories={this.state.data.categories}
											onChangeQuantity={this.onChangeQuantity}
											onChangeSelection={this.onChangeSelection}
										/>
									</ContentContainer>
								)
						}
						{lowerSection}
					</LeftSideContainer>
					<CheckoutContainer fullscreen={onLastSection}>
						<OrderSummary
							onLastSection={onLastSection}
							data={this.state.data}
							subtotal={subtotal}
							tax={tax}
							total={totalPrice}
							address={this.state.setupAddress}
							comments={this.state.comments}
							setupDate={this.state.setupDate}
							teardownDate={this.state.teardownDate}
							onRemoveClickedForSectionItem={this.removeAddedItemFromCartAtIndex}
							onRemoveClickedForSelectableItem={this.removeSelectableItemFromCart}
							onCommentsChanged={this.onCommentsChanged}
						/>
						{onLastSection ? tAndC : null}
						{onLastSection ? checkoutButton : null}
						{onLastSection && !formValidityCheck.valid ? issueResolver : null}
					</CheckoutContainer>
				</CenterContentContainer>
			</OrderPageContainer>
		);
	}

	resolveIssueFor = ({code}) => {
		switch (code) {
			case FormCodes.TERMS:
				this.setState({termsAccepted: true});
				break;
			case FormCodes.HOUSE_SIZE:
				this.changeToSectionAtIndex(0);
				break;
			case FormCodes.SETUP_ADDRESS:
				this.changeToSectionAtIndex(8);
				break;
			default:
				break;
		}
	};

	removeSelectableItemFromCart = (itemName, categoryType) => {
		this.setState(state =>  {
			let updatedData = state.data;

			const categoryIndex = updatedData.categories.map(category => category.type).indexOf(categoryType);
			let updatedCategory = updatedData.categories[categoryIndex];

			updatedCategory.items = updatedCategory.items.map(item => {
				item.selected = item.includedWithBase;
				return item;
			});

			return {
				data: updatedData
			};
		});
	};

	onCommentsChanged = (newComments) => {
		this.setState({
			comments: newComments
		});
	};

	handleSetupAddressChange = setupAddress => {
		this.setState({ setupAddress });
	};

	removeAddedItemFromCartAtIndex = (sectionIndex, indexOfClickedItem) => {
		this.setState(state => {
			let updatedData = state.data;
			let updatedSections = updatedData.sections;
			const updatedSection = updatedSections[sectionIndex];

			const itemBeingRemoved = updatedSection.addedItems[indexOfClickedItem];
			const categoryIndex = updatedData.categories.map(cat => cat.type).indexOf(itemBeingRemoved.category);
			let updatedCategory = updatedData.categories[categoryIndex];

			let updatedItem = updatedCategory.items[itemBeingRemoved.indexOfItemInCategory];
			updatedItem.quantity = Math.max(0, updatedItem.quantity - 1);
			updatedCategory.items[itemBeingRemoved.indexOfItemInCategory] = updatedItem;

			updatedData.categories[categoryIndex] = updatedCategory;

			updatedSection.addedItems = this.getAddedItemsForBuilderSection(updatedSection, updatedData.categories);

			updatedData.sections = updatedSections;

			return {
				data: updatedData
			}
		});
	};

	changeToSectionAtIndex = (newIndex, ignorePush, showNotification) => {
		if (newIndex !== this.state.selectedSection) {

			this.setState(state => {
				!ignorePush && this.props.history.push('/' + state.data.sections[newIndex].data);
				Analytics.logSectionChange(state.data.sections[newIndex].data);

				if (state.selectedSection !== state.data.sections.length - 1) {
					state.data.sections[state.selectedSection].visited = true;
				}
				state.selectedSection = newIndex;
				state.isBuilder = state.data.sections[newIndex].type === "builder";
				return state;
			});

			this.leftSideContainer.current.scrollTo(0, 0);
			window.scrollTo(0, 0);
			setTimeout(() => {
				showNotification && this.showSaveNotification();
			}, 100);
		}
	};

	onSaveClicked = () => {
		Analytics.logSaveButtonPressed(this.state.data.sections[this.state.selectedSection].data);
		this.showSaveNotification();
	};

	showSaveNotification = () => {
		NotificationCenter.addNotification({
			content: <SuccessNotification/>,
			type: "success",
			insert: "bottom",
			container: "top-center",
			animationIn: ["animated", "fadeInDown", "faster"],
			animationOut: ["animated", "fadeOutUp", "faster"],
			dismiss: {
				duration: 4000,
				showIcon: true
			},
		});
	};

	nextSectionClicked = () => {
		const newIndex = Math.min(this.state.selectedSection + 1, this.state.data.sections.length - 1);
		this.changeToSectionAtIndex(newIndex, false, true);
	};

	calculateTotalPrice = () => {
		return this.state.data.basePrice +
			this.state.data.sections
				.map( section => this.calculatePriceFor(section) )
				.reduce((total, incoming) => total + incoming, 0);
	};

	calculatePriceFor = (section) => {
		if (section.type === "selector") {
			const foundCategories = this.state.data.categories.filter(category => section.categories.includes(category.type));
			return foundCategories.map(category =>
				category.items.reduce((total, item) => total + (item.selected ? item.price : 0), 0)
			).reduce((total, current) => total + current, 0);
		} else if (section.type === "builder") {
			const totalForItems = section.addedItems.reduce((prev, current) => {
				return prev + current.price;
			}, 0);
			return Math.max(totalForItems - section.allowance, 0);
		} else {
			return 0;
		}
	};

	onChangeQuantity = (sectionDataName, categoryType, itemName, newQuantity) => {
		this.setState(state => {
			let updatedData = state.data;

			const categoryIndex = updatedData.categories.map(cat => cat.type).indexOf(categoryType);
			let updatedCategory = updatedData.categories[categoryIndex];

			const itemIndex = updatedCategory.items.map(item => item.name).indexOf(itemName);
			let updatedItem = updatedCategory.items[itemIndex];
			updatedItem.quantity = newQuantity;

			updatedCategory.items[itemIndex] = updatedItem;
			updatedData.categories[categoryIndex] = updatedCategory;

			const sectionIndex = updatedData.sections.map(sect => sect.data).indexOf(sectionDataName);
			let updatedSection = updatedData.sections[sectionIndex];

			updatedSection.addedItems = this.getAddedItemsForBuilderSection(updatedSection, updatedData.categories);

			Analytics.logQuantityChangedForItem(updatedCategory.categoryName, itemName, newQuantity);

			return {
				data: updatedData
			};
		});
	};

	onChangeSelection = (sectionDataName, categoryName, itemName, itemPrice, itemIndex) => {
		this.setState(state => {
			let updatedData = state.data;

			const categoryIndex = updatedData.categories.map(category => category.categoryName).indexOf(categoryName);
			let updatedCategory = updatedData.categories[categoryIndex];

			updatedCategory.items = updatedCategory.items.map(item => {
				item.selected = item.name === itemName;
				return item;
			});
			updatedData.categories[categoryIndex] = updatedCategory;

			const sectionIndex = updatedData.sections.map(sect => sect.data).indexOf(sectionDataName);
			let updatedSection = updatedData.sections[sectionIndex];

			if (updatedSection.type === "builder") {
				updatedSection.addedItems = this.getAddedItemsForBuilderSection(updatedSection, updatedData.categories);
			}

			if (updatedCategory.affectsCategories && updatedCategory.affectsCategories.length > 0) {
				const updatedItem = updatedCategory.items[itemIndex];
				updatedData.categories = updatedData.categories.map(category => {
					if (updatedCategory.affectsCategories.includes(category.type)) {
						category.items = category.items.map(item => {
							item.selected = item.name === updatedItem[category.type];
							return item;
						});
					}

					return category;
				});

				updatedData.sections = updatedData.sections.map(section => {
					if (section.type === "builder" && section.categories.some(sectionCat => updatedCategory.affectsCategories.includes(sectionCat))) {
						section.addedItems = this.getAddedItemsForBuilderSection(section, updatedData.categories);
					}

					return section;
				});
			}

			if (updatedCategory.affectsPrices && updatedCategory.affectsPrices.length > 0) {
				const updatedItem = updatedCategory.items[itemIndex];
				updatedData.categories = updatedData.categories.map(category => {
					if (updatedCategory.affectsPrices.includes(category.type)) {
						const prices = updatedItem[category.type + "Prices"];
						category.items = category.items.map((item, relatedItemIndex) => {
							item.price = prices[relatedItemIndex];
							return item;
						});
					}
					return category;
				});
			}

			Analytics.logItemSelected(categoryName, itemName);

			return {
				data: updatedData
			}
		});
	};

	getAddedItemsForBuilderSection = (section, allCategories) => {
		let list = [];

		const foundCategories = allCategories.filter(category => section.categories.includes(category.type));

		let additionalSelectedOptions = "";

		foundCategories.forEach(category => {
			additionalSelectedOptions += category.selectable ? category.items.reduce((compiledName, item) => {
				return compiledName + (item.selected ? "\n" + item.name : "");
			}, "") : "";
		});

		foundCategories.forEach(category =>
			!category.selectable && category.items.forEach((item, indexOfItem) => {
				[...Array(item.quantity)].forEach(() => {
					list.push({
						category: category.type,
						indexOfItemInCategory: indexOfItem,
						name: item.name + additionalSelectedOptions,
						price: item.price
					});
				});
			})
		);

		return list;
	};

	isFormValid = () => {

		const hasSelectedHomeSize = this.state.data.categories.filter(category => category.type === "home")[0]
			.items.filter(item => item.selected)
			.length > 0;

		if (!this.state.termsAccepted) {
			return {valid: false, reason: "You must accept the Terms and Conditions before checking out.", code: FormCodes.TERMS};
		} else if (!hasSelectedHomeSize) {
			return {valid: false, reason: "You must select a Home Size before checking out.", code: FormCodes.HOUSE_SIZE};
		} else if (this.state.setupAddress.trim().length === 0) {
			return {valid: false, reason: "You must enter a Setup Address before checkout out.", code: FormCodes.SETUP_ADDRESS};
		}

		return {valid: true, reason: ""};
	};
}
