import React, { Component } from 'react';
import styled from 'styled-components';
import Style from "../constants/style";
import { ImageBaseURL } from '../constants/server';
import {ScreenSize} from "../constants/screensize";
import Select from "react-select";
import PropTypes from 'prop-types';

const MarginAutoContainer = styled.div`
	margin: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
`;

const SelectableItem = styled.div`
	border-radius: ${Style.borderRadius}px;
	width: 250px !important;
	margin: 10px;
	position: relative;
	transition: all 0.15s ease-out;
	background: ${props => props.selected ? Style.primaryGreen : 'white'};
	color: ${props => props.selected ? Style.darkGreen : 'black'};
	border: ${props => props.selected ? ('2px solid ' + Style.darkGreen) : '2px solid lightgrey'};
	display: flex;
	align-items: center;
	flex-direction: ${props => props.small ? 'row' : 'column'};
	
	@media (max-width: ${ScreenSize.laptop}px) {
		width: 200px !important;
		margin: 8px;
	}
	
	@media (max-width: ${ScreenSize.phone}px) {
		width: 46vw !important;
		margin: 3px;
	}
	
	:hover {
		cursor: ${props => props.allowSelection ? 'pointer' : ''};
		background: ${props => !props.allowSelection ? '' : (props.selected ? Style.primaryGreen : 'lightgray')};
	}
	
	:active {
		background: ${props => props.allowSelection ? 'gray' : ''};
	}
`;

const Header = styled.div`
	text-align: ${props => props.small ? 'left' : 'center'};
	font-size: larger;
	padding: ${props => props.small ? '0 15px' : '15px 0'};
	font-weight: 800;
	justify-content: center;
	align-items: center;
	display: flex;
	height: 46px;
	user-select: none;
	
	@media (max-width: ${ScreenSize.laptop}px) {
		height: 30px;
		font-size: ${props => props.small ? 'smaller' : 'inherit'};
		padding: ${props => props.small && '0 0 0 15px'};
	}
`;

const Image = styled.img`
	width: ${props => props.small ? '46px' : '100%'};
	height: ${props => props.small ? 46 : 200}px;
	object-fit: cover;
	border-top-right-radius: ${Style.borderRadius}px;
	border-top-left-radius: ${Style.borderRadius}px;
	
	@media (max-width: ${ScreenSize.laptop}px) {
		width: ${props => props.small ? '46px' : '100%'};
		height: ${props => props.small ? 46 : 160}px;
	}
`;

const QuantityContainer = styled.div`
	width: 90%;
	margin: auto;
	display: flex;
	align-items: center;
	padding-bottom: 20px;
`;

const QuantityLabel = styled.div`
	flex: 1;
	padding-right: 5px;
	user-select: none;
	
	@media (max-width: ${ScreenSize.phone}px) {
		display: none;
	}
`;

const QtyLabel = styled(QuantityLabel)`
	display: none;
	
	@media (max-width: ${ScreenSize.phone}px) {
		display: initial;
	}
`;

const Dropdown = styled(Select)`
	flex: 2;
`;

class ItemBlocks extends Component {
	render() {
		const items = this.props.category.items;
		const options = items.map(item => {
			let label = item.name;
			if (item.price > 0) {
				label += ` (+$${item.price})`
			}
			const imageBasePath = this.props.imageBasePath || item.imageBasePath || "";
			return {
				value: item.name,
				label: label,
				selected: item.selected,
				image: imageBasePath + item.image,
				price: item.price,
				quantity: item.quantity
			}
		});

		const isSmall = this.props.category.small || (this.props.category.smallOnMobile && this.props.isMobile) || false;

		const quantityOptions = [...Array(21).keys()].map (v => ({ value: v, label: v }));

		return (
			<MarginAutoContainer>
				{options.map((item, index) => (
					<SelectableItem allowSelection={!this.props.isPartOfQuantityBuilder} key={item.label} small={isSmall} selected={item.selected || (this.props.isPartOfQuantityBuilder && item.quantity > 0)} onClick={() => this.onClick(item, index)}>
						<Image src={ImageBaseURL + item.image} small={isSmall}/>
						<Header small={isSmall}>
							{item.value}
						</Header>
						{this.props.isPartOfQuantityBuilder ? (
							<QuantityContainer>
								<QuantityLabel>Quantity:</QuantityLabel>
								<QtyLabel>Qty:</QtyLabel>
								<Dropdown value={{value: item.quantity, label: item.quantity}} options={quantityOptions} searchable onChange={(option) => this.onChangeQuantity(item.value, option)} />
							</QuantityContainer>
						) : null}
					</SelectableItem>
				))}
			</MarginAutoContainer>
		);
	}

	onClick = (item, index) => {
		if ((!item.selected && !this.props.isPartOfQuantityBuilder)) {
			this.props.onChangeSelection(this.props.sectionDataName, this.props.category.categoryName, item.value, item.price, index, item.image);
		}
	};

	onChangeQuantity = (itemName, selectedOption) => {
		this.props.onChangeQuantity(this.props.sectionDataName, this.props.category.type, itemName, selectedOption.value);
	};
}
ItemBlocks.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	category: PropTypes.object.isRequired,
	sectionDataName: PropTypes.string.isRequired,
	isPartOfQuantityBuilder: PropTypes.bool.isRequired,
	onChangeSelection: PropTypes.func.isRequired,
};

export default ItemBlocks;
