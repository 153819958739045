import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Style from "../constants/style";
import WelcomeHeader from "./WelcomeHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ScreenSize} from "../constants/screensize";

const Container = styled.div`
	width: 100vw;
	font: inherit;
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	background-color: white;
	border-bottom: 1px solid gray;
	text-align: center;
`;

const SectionContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	height: 100px;
	
	@media (max-width: ${ScreenSize.phone}px) {
		height: 80px;
	}
`;

const IconWrapper = styled.span`
	font-size: 24px;
	padding: 5px;
`;

const Item = styled.a`
	user-select: none;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: ${props => props.selected ? Style.primaryGreen : 'white'};
	color: ${props => props.selected ? Style.darkGreen : 'black'};
	font-weight: 800;
	font-size: larger;
	border-right: 1px solid lightgrey;
	
	@media (max-width: ${ScreenSize.laptop}px) {
		font-size: inherit;
	}
	
	@media (max-width: ${ScreenSize.tablet}px) {
		font-size: smaller;
	}
	
	span {
		color: ${props => props.visited || props.selected ? Style.darkGreen : 'black'};
	}
	
	:hover, :hover span {
		color: ${props => !props.noSelect && !props.noHover && Style.darkGreen};
		background-color: ${props => !props.noSelect && !props.noHover && '#80cf1c'};
		cursor: ${props => !props.noSelect && !props.noHover && 'pointer'};
	}
	
	:active, :active span {
		color: ${props => !props.noSelect && 'white'};
		background-color: ${props => !props.noSelect && Style.primaryGreenHighlight};
	}
	
	:last-child {
		border-right: none;
	}
`;

class SectionNavigator extends Component {
	constructor(props) {
		super(props);

		this.previousButton = React.createRef();
		this.nextButton = React.createRef();
		this.twoFromNowButton = React.createRef();
	}

	render() {
		const sectionElements = this.props.isMobile ? this.getMobileItems() : this.getFullItems();

		return (
			<Container>
				<WelcomeHeader>
					Customize Your Seasonal Setter Package
				</WelcomeHeader>
				{sectionElements}
			</Container>
		);
	}

	getMobileItems = () => {
		const previousItem = this.props.selectedSection > 0 ? this.props.sections[this.props.selectedSection - 1] : null;
		const selectedItem = this.props.sections[this.props.selectedSection];
		const nextItem = this.props.selectedSection < this.props.sections.length - 1 ? this.props.sections[this.props.selectedSection + 1] : null;
		return (
			<SectionContainer>
				{previousItem !== null ? (
					<Item visited noHover ref={this.previousButton} onClick={() => {this.previousButton.current.blur(); this.props.onSectionClicked(this.props.selectedSection - 1);}}>
						<IconWrapper>
							<FontAwesomeIcon icon='check'/>
						</IconWrapper>
						<div>
							{previousItem.label}
						</div>
					</Item>
				) : null}

				<Item noSelect selected={true}>
					<IconWrapper>
						<FontAwesomeIcon icon={selectedItem.icon} />
					</IconWrapper>
					<div>
						{selectedItem.label}
					</div>
				</Item>

				{nextItem !== null ? (
					<Item noHover ref={this.nextButton} onClick={() => {this.nextButton.current.blur(); this.props.onSectionClicked(this.props.selectedSection + 1);}}>
						<IconWrapper>
							<FontAwesomeIcon icon={nextItem.icon}/>
						</IconWrapper>
						<div>
							{nextItem.label}
						</div>
					</Item>
				) : (
					<Item noSelect />
				)}

				{previousItem === null ? (
					<Item noHover ref={this.twoFromNowButton} onClick={() => {this.twoFromNowButton.current.blur(); this.props.onSectionClicked(this.props.selectedSection + 2);}}>
						<IconWrapper>
							<FontAwesomeIcon icon={this.props.sections[this.props.selectedSection + 2].icon}/>
						</IconWrapper>
						<div>
							{this.props.sections[this.props.selectedSection + 2].label}
						</div>
					</Item>
				) : null}
			</SectionContainer>
		)
	};

	getFullItems = () => {
		const list = this.props.sections.map((item, index) => {
			const icon = item.visited ? "check" : item.icon;
			const selected = this.props.selectedSection === index;
			return (
				<Item key={item.label} onClick={() => this.props.onSectionClicked(index)} selected={selected} visited={item.visited}>
					<IconWrapper selected={selected}>
						<FontAwesomeIcon icon={icon}/>
					</IconWrapper>
					<div>
						{item.label}
					</div>
				</Item>
			);
		});

		return (
			<SectionContainer>
				{list}
			</SectionContainer>
		)
	};
}

SectionNavigator.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	sections: PropTypes.array.isRequired,
	selectedSection: PropTypes.number.isRequired,
	onSectionClicked: PropTypes.func.isRequired
};

export default SectionNavigator;
