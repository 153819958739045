import React, {Component} from 'react';
import styled from 'styled-components';
import WelcomeHeader from "./WelcomeHeader";
import {Analytics} from "../utils/Analytics";
import {ImageBaseURL} from "../constants/server";
import {ScreenSize} from "../constants/screensize";
import Style from "../constants/style";
import store from "store";
import versionNumber from "../version";
import Button from "./Button";
import {PackageHelper} from "../utils/PackageHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
	text-align: center;
	background-color: white;
	background-image: url(${ImageBaseURL + "/white_tree_bg.jpg"});
	background-size: cover;
	background-position: center;
	min-height: 100vh;
`;

const CenterContentContainer = styled.div`
	width: 90%;
	min-height: ${props => 'calc(100vh - ' + (props.returning ? 100 : 174) + 'px)'};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 28px;
	margin: 5vh auto auto;

	@media (max-width: ${ScreenSize.tablet}px) {
		min-height: ${props => 'calc(100vh - ' + (props.returning ? 70 : 149) + 'px)'};
		font-size: 24px;
	}

	@media (max-width: ${ScreenSize.phone}px) {
		min-height: ${props => 'calc(100vh - ' + (props.returning ? 100 : 149) + 'px)'};
		font-size: 20px;
		justify-content: flex-start;
		padding-top: ${props => props.returning ? 60 : 20}px;
	}
`;

const ButtonContainer = styled.div`
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding-bottom: 20vh;

	@media (max-width: ${ScreenSize.phone}px) {
		flex-direction: column;
		align-items: center;
	}
`;

const ButtonWrapper = styled.div`
	width: 49%;

	@media (max-width: ${ScreenSize.phone}px) {
		padding-top: 20px;
		width: 100%;
	}
`;

const CompanyName = styled.h3`
	margin: 0;
`;

const Slogan = styled.h4`
	margin: 0;
	font-weight: 200;
	font-size: smaller;
`;

const PackageName = styled.h3`
	margin: 0;
`;

const Price = styled.h4`
	margin: 0;
`;

const H4 = styled.h4`
	margin: 0 0 50px;
	font-weight: 400;
	font-size: smaller;
`;

const ThemeContainer = styled.div`
	width: 27vw;
	text-align: left;
	font-size: 0.7em;
	padding-bottom: 5vw;

	@media (max-width: ${ScreenSize.phone}px) {
		width: 80vw;
	}
`;

const ThemeButton = styled.div`
	border-radius: ${Style.borderRadius}px;
	border: 3px solid lightgrey;
	background: white;
	width: 100%;
	height: 27vw;
	display: flex;
	flex-direction: column;
	font-size: larger;

	@media (max-width: ${ScreenSize.laptop}px) {
		height: 45vw;
	}

	@media (max-width: ${ScreenSize.phone}px) {
		width: 80vw;
		height: 80vw;
	}

	:hover {
		cursor: pointer;
		background: ${Style.primaryGreen};
	}

	:active {
		background: ${Style.primaryGreenHighlight};
	}
`;

const TopPictureArea = styled.div`
	background: grey;
	width: 100%;
	flex: 8;
	overflow: hidden;
`;

const Image = styled.img`
	width: 100%;
	object-fit: cover;
`;

const BottomStrip = styled.div`
	font-weight: 600;
	width: 100%;
	flex: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
`;

const MiniText = styled.div`
	font-size: smaller;
	color: ${props => props.grey ? 'grey' : Style.darkGreen};
`;

const BelowButtonContent = styled.div`
	width: 90%;
	margin: auto;

	@media (max-width: ${ScreenSize.phone}px) {
		font-size: larger;
	}
`;

const StrongAndLarger = styled.span`
	font-weight: 600;
	font-size: larger;
`;

const NoBulletList = styled.ul`
	list-style: none;
	margin-left: 0;
	padding-left: 0.5em;
`;

class LandingPage extends Component {
	constructor(props) {
		super(props);

		Analytics.logCurrentURL();

		let localStorage = store.get('state');
		if (localStorage !== undefined && localStorage.version === versionNumber.version) {
			this.state = {
				returning: true,
				selectedSection: localStorage.data.sections[localStorage.selectedSection].data
			}
		} else {
			this.state = {
				returning: false
			}
		}
	};

	render() {
		const returningCustomer = (
			<CenterContentContainer returning>
				<div>
					<PackageName>Continue?</PackageName>
					<Price>It looks like you have a previously-saved Build Your Own order.</Price>
					<p>
						Would you like to continue this, or start a new order?
					</p>
					<ButtonContainer>
						<ButtonWrapper>
							<Button onClick={this.onNewOrderClicked}>
								Start New Order
							</Button>
						</ButtonWrapper>
						<ButtonWrapper>
							<Button onClick={this.onContinueClicked}>
								Continue Order
							</Button>
						</ButtonWrapper>
					</ButtonContainer>
				</div>
			</CenterContentContainer>
		);

		const packages = [
			{name: "Ground-Only Package", price: "$500 Flat", type: "ground", subtitle: "Takes 3 mins.", image: "packages/ground.jpg"},
			{name: "Full-Home Package", price: "$850 Flat", type: "fullhome", subtitle: "Takes 3 mins.", image: "packages/fullhome_coolwhite.jpg"},
			{name: "Build Your Own Package", price: "Starting at $850", type: "byo", subtitle: "Takes 10~15 mins.", image: "packages/byo.jpg"}
		];

		const newCustomer = (
			<CenterContentContainer>
				<PackageName>
					What Package Would You Like To Rent?
				</PackageName>
				<H4>We handle Setup & Teardown so you don't have to.</H4>
				<ButtonContainer>
					{packages.map(item => (
						<ThemeContainer key={item.name}>
							<ThemeButton onClick={() => this.onPackageClicked(item.type, item.name)}>
								<TopPictureArea>
									<Image src={ImageBaseURL + "/" + item.image}/>
								</TopPictureArea>
								<BottomStrip>
									{item.name}
									<MiniText>
										<MiniText grey>
											{item.subtitle}
										</MiniText>
										<br/>
										{item.price}
										<br/>
									</MiniText>
								</BottomStrip>
							</ThemeButton>
							<BelowButtonContent>
								<br/>
								For <StrongAndLarger>{item.price}</StrongAndLarger> you get:
								<NoBulletList>
									{PackageHelper.getHighLevelPackageItemsFor(item.type).map(item =>
										<li key={item}>
											<FontAwesomeIcon icon={faCheck} /> &nbsp; {item}
										</li>
									)}
								</NoBulletList>
							</BelowButtonContent>
						</ThemeContainer>
					))}
				</ButtonContainer>
			</CenterContentContainer>
		);

		return (
			<Container>
				<WelcomeHeader>
					<CompanyName>Seasonal Setter</CompanyName>
					<Slogan>Our Lights. Our Setup. Your Home.</Slogan>
				</WelcomeHeader>
				{this.state.returning ? returningCustomer : newCustomer}
			</Container>
		);
	}

	onPackageClicked = (type, name) => {
		store.clearAll();
		Analytics.logPackageStarted(name);
		let route = type === "ground" ? "/ground/theme" : type === "fullhome" ? "/fullhome/size" : "/homesize";
		this.props.history.push(route)
	};

	onNewOrderClicked = () => {
		Analytics.logNewOrderClicked();
		store.clearAll();
		this.setState(state => ({returning: false}));
	};

	onContinueClicked = () => {
		Analytics.logContinueOrderClicked();
		this.props.history.push('/' + this.state.selectedSection)
	};
}

LandingPage.propTypes = {};

export default LandingPage;
