import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import WelcomeHeader from "./WelcomeHeader";
import {ImageBaseURL} from "../constants/server";
import {ScreenSize} from "../constants/screensize";
import Style from "../constants/style";
import store from 'store';
import {Analytics} from "../utils/Analytics";
import {PackageHelper} from "../utils/PackageHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
	text-align: center;
	background-color: white;
	background-image: url(${ImageBaseURL + "/white_tree_bg.jpg"});
	background-size: cover;
	background-position: center;
	min-height: 100vh;
`;

const PackageDescription = styled.h5`
	margin: 0;
	font-weight: 200;
`;

const CenterContentContainer = styled.div`
	width: 90%;
	min-height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 28px;
	margin: 5vh auto auto;

	@media (max-width: ${ScreenSize.tablet}px) {
		font-size: 24px;
	}

	@media (max-width: ${ScreenSize.phone}px) {
		font-size: 20px;
		justify-content: flex-start;
		padding-top: ${props => props.returning ? 60 : 20}px;
	}
`;

const ButtonContainer = styled.div`
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@media (max-width: ${ScreenSize.phone}px) {
		margin-bottom: 50vh;
		flex-direction: column;
		align-items: center;
	}
`;

const PackageName = styled.h3`
	margin: 0;
`;

const H4 = styled.h4`
	margin: 0;
`;

const PriceBlock = styled.div`
	padding: 50px 0 50px;
`;

const ThemeContainer = styled.div`
	width: 27vw;
	text-align: left;
	font-size: 0.7em;
	padding-bottom: 5vw;

	@media (max-width: ${ScreenSize.phone}px) {
		width: 80vw;
	}
`;

const ThemeButton = styled.div`
	border-radius: ${Style.borderRadius}px;
	border: 3px solid lightgrey;
	background: white;
	width: 100%;
	height: 27vw;
	display: flex;
	flex-direction: column;
	font-size: larger;

	@media (max-width: ${ScreenSize.phone}px) {
		width: 80vw;
		height: 80vw;
	}

	:hover {
		cursor: pointer;
		background: ${Style.primaryGreen};
	}

	:active {
		background: ${Style.primaryGreenHighlight};
	}
`;

const MiniText = styled.div`
	font-size: 0.5em;
`;

const TopPictureArea = styled.div`
	background: grey;
	width: 100%;
	flex: 8;
	overflow: hidden;
`;

const Image = styled.img`
	width: 100%;
	object-fit: cover;
`;

const BottomStrip = styled.div`
	font-weight: 600;
	width: 100%;
	flex: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

const BelowButtonContent = styled.div`
	width: 90%;
	margin: auto;

	@media (max-width: ${ScreenSize.phone}px) {
		font-size: larger;
	}
`;

const StrongAndLarger = styled.span`
	font-weight: 600;
	font-size: larger;
`;

const NoBulletList = styled.ul`
	list-style: none;
	margin-left: 0;
	padding-left: 0.5em;
`;

class PackageThemePage extends Component {
	constructor(props) {
		super(props);

		store.set('isGround', props.isGround);

		const homeSize = store.get('homeSize');

		this.state = {
			homeSize: homeSize !== undefined ? homeSize : 1
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		Analytics.logCurrentURL();
	}

	render() {
		const step = this.props.isGround ?
			"Step 1 of 2" :
			"Step 2 of 3";

		const packageDescription = this.props.isGround ?
			"For those who want to keep it simple, but still be festive." :
			"For those who want their home to look stunning & full.";

		const packageName = this.props.isGround ? "Ground-Only Package" : "Full-Home Package";

		const price = this.calculatePrice();

		const themes = ["Cool White", "Warm White", "Multicolor"];
		const images = ["packages/fullhome_coolwhite.jpg", "packages/warmwhite.jpg", "packages/multicolor.jpg"];

		return (
			<Container>
				<WelcomeHeader>
					<PackageName>{packageName}</PackageName>
					<PackageDescription>{packageDescription}</PackageDescription>
				</WelcomeHeader>
				<CenterContentContainer>
					<PackageName>
						What Theme Do You Want?
					</PackageName>
					<H4>{step}</H4>
					<PriceBlock>
						${price} Flat
						<br/>
						<MiniText>
							Regardless of which theme you pick.
						</MiniText>
					</PriceBlock>
					<ButtonContainer>
						{themes.map((theme, index) => (
							<ThemeContainer key={theme}>
								<ThemeButton onClick={() => this.onThemeClicked(theme)}>
									<TopPictureArea>
										<Image src={ImageBaseURL + "/" + images[index]}/>
									</TopPictureArea>
									<BottomStrip>
										{theme}
									</BottomStrip>
								</ThemeButton>
								<BelowButtonContent>
									<br/>
									For <StrongAndLarger>${price}</StrongAndLarger> you get:
									<NoBulletList>
										{PackageHelper.getDetailedPackageItemsFor(this.props.isGround, theme).map(item =>
											<li key={item}>
												<FontAwesomeIcon icon={faCheck} /> &nbsp; {item}
											</li>
										)}
									</NoBulletList>
								</BelowButtonContent>
							</ThemeContainer>
						))}
					</ButtonContainer>
				</CenterContentContainer>
			</Container>
		);
	}

	calculatePrice = () => {
		return this.props.isGround ? 500 : 850;
	};

	onThemeClicked = (theme) => {
		store.set('theme', theme);
		Analytics.logPackageThemePicked(theme);
		const route = this.props.isGround ? '/ground/options' : '/fullhome/options';
		this.props.history.push(route);
	};
}

PackageThemePage.propTypes = {
	isGround: PropTypes.bool.isRequired,
};

PackageThemePage.defaultProps = {
	isGround: false,
};

export default PackageThemePage;
