
const Style = {
	borderRadius: 4,
	borderColor: "#66cc33",
	primaryGreen: "#A9ED53",
	primaryGreenHighlight: "#5e9f0c",
	darkGreen: 'rgba(14,117,24,0.988)',
	transparentBlack: "rgba(0,0,0,0.75)",
};

export default Style;
