export const Terms = "# Terms and Conditions \n## 1. SCOPE OF WORK.\n" +
"Scope will be determined by package and options purchased. Client will be referred to as the person purchasing the package. Client’s property (or Recipient's property) shall be decorated using the scope of each package as per purchase. By agreeing to these terms and conditions, the client confirms acceptance of scope of work and all articles within.\n" +
"## 2. TERM.\n" +
"The term of this Agreement is for the winter holiday season of 2023 from the date of its execution by Seasonal Setter or until the completion of the work described in any Statement of Work, whichever is later. Specific set-up date will be determined by online calendar at time of purchase.\n" +
"## 3. PAYMENT.\n" +
"Client will have the option to pay either 20% of the total cost, or total cost of purchase. Seasonal Setter will charge the remaining balance using the same payment method within 5 calendar days after setup date. It is understood that payment to Seasonal Setter for services rendered shall be made in full as agreed, without any deductions of any kind whatsoever, in conformity with Seasonal Setter’s status as an independent contractor. Payment method will be through debit/credit transaction, unless otherwise specified prior to the time of purchase.\n" +
"## 4. ACCEPTANCE.\n" +
"The Decorations, as defined in each Statement of Work, shall be deemed accepted by Client upon completion of the following acceptance test: (a) immediately upon receipt of said Decorations, Client shall promptly, but in any event not more than 5 business days, perform testing of the Decorations to confirm that the Decorations perform in accordance with the documentation or other standards applicable thereto as set forth in the applicable Statement of Work; (b) notification of nonconformities in regards to decorations setup or product functionality to be corrected must before December 15th, 2023. Any such written statement of nonconformities shall provide sufficient detail to enable Seasonal Setter to remedy the failure to conform to the completion criteria contained in the written statement. Unless otherwise agreed to in writing by the parties, Seasonal Setter will redeliver corrected Decorations to Client within a reasonable amount of time after receipt of such statement of nonconformities or by December 15th, 2022. If Client fails to provide a written acceptance or a written statement of nonconformities about corrected decorations within the 5 days, the Deliverables shall be deemed accepted by Client.\n" +
"## 5. LIMITATION OF LIABILITY.\n" +
"Seasonal Setters LLC will be liable for any damages caused during the set-up or take-down of equipment (decorations) to the Client’s person or property. Seasonal Setter is also responsible to ensure all decorations that are set-up initially work and are securely fastened properly. Seasonal Setter will be responsible to make sure that any client concerns (such as burnt-out light bulbs) are addressed before December 20th, 2023 (weather permitting). Seasonal Setters LLC is NOT liable for any damages caused by electrical work, including but not limited to; fires, broken light bulb glass, power surges, etc.. Client is responsible for ensuring the electrical system on property is able to handle the electrical load, and has the sole responsibility to make sure it is able to provide proper power. Similarly, Seasonal Setter is not liable for providing any electricity to any equipment set up or provided. Client is fully responsible for any equipment that Seasonal Setter sets up during the duration of rental and/or service contract. Client will be liable for any lost or stolen equipment owned by Seasonal Setter during the duration of rental up until the date of actual teardown of decorations by Seasonal Setter.\n" +
"## 6. PROPERTY & EQUIPMENT POLICIES\n" +
"Client may not transfer, sell, or otherwise dispose of any Seasonal Setter equipment without the prior written consent of Seasonal Setter. Client will not alter, modify, add or subtract any form of adhesive, wire, hooks, clips, or any other items to the decorations or lights set up by Seasonal Setter. Client is responsible to notify Seasonal Setter if decorations are unsatisfactory or if they have a concern. This rental gives no title or ownership rights in related property to Client.\n" +
"## 7. ENTIRE AGREEMENT.\n" +
"This Agreement sets forth the entire agreement between Client and Seasonal Setter as to its subject matter. None of the terms of this Business Contract shall be amended except in writing signed by both parties unless otherwise communicated and agreed upon in writing.\n" +
"## 8. FORCE MAJEURE.\n" +
"Neither Client nor Seasonal Setter shall be liable for failure of, or delay in performing obligations set forth in this Agreement, and neither shall be deemed in breach of its obligations, if such failure or delay is due to natural disasters or any causes reasonably beyond the control of Client or Provider. This Agreement shall be construed in accordance with the laws of the State of OHIO. IN WITNESS WHEREOF the parties have caused this Business Contract to be executed and delivered by their duly authorized representatives.\n" +
"## 9. CANCELLATION POLICY.\n" +
"Seasonal Setter reserves the right to cancel any scope of work for a Client. If Seasonal Setter cancels any scope of work, the Client will be reimbursed for the work that was not done. Full cancellations initiated by Seasonal Setter will get a full refund. Reasons include but are not limited to: Safety risk, Order is too far outside of the Columbus Region, Irreconcilable differences, etc. Exclusions to reimbursement may apply if there are damages done to Seasonal Setter property. Client may also initiate a cancellation but could be subject to a restocking, order fee, or other penalties from Seasonal Setter within a reasonable manner.\n" +
"## 10. USE OF PHOTOGRAPHY.\n" +
"Client agrees to allow Seasonal Setter to take and use photos of the client's home in regard to showing the display and work that Seasonal Setter has displayed. Any exception of this must be documented in writing by the client and received by Seasonal Setter prior to the installation of the lights.\n" +
"## 11. LIMITS OF ORDERING.\n" +
"If the Client is purchasing a package as a gift to another party, referred to as Recipient, Seasonal Setter must be fully informed of this action and must have written permission from Recipient and the Homeowner of the Address being decorated. This must be disclosed at the time of ordering.";
