import ReactGA  from 'react-ga';
import ReactFB from "./ReactFB";
const isAnalyticsAllowed = process.env.NODE_ENV === 'production';

const Category = {
	OrderBuilding: "Order Building",
	Navigation: "Navigation",
	Checkout: "Checkout"
};

const GoogleAnalyticsWrapper = {
	init: () => {
		if (!isAnalyticsAllowed) {
			console.log("init google analytics");
			return;
		}

		ReactGA.initialize('UA-150294374-1');
		ReactGA.plugin.require('ec');
		window.gtag('js', new Date());
		window.gtag('config', 'AW-697368105');
	},


	// ARGS:
	//  event: object {
	//    category: string
	//  	action: string
	//    label: string (optional)
	//    value: int (optional)
	//    nonInteraction: bool (optional)
	//  }
	logEvent: (event) => {
		if (!isAnalyticsAllowed) {
			console.log("Log event:");
			console.log(event);
			return;
		}

		ReactGA.event(event);
	},

	// ARGS:
	//  pagename: string
	logPageView: (pagename) => {
		const pageToLog = pagename || "/";

		if (!isAnalyticsAllowed) {
			console.log("Log pageview:");
			console.log(pageToLog);
			return;
		}

		ReactGA.pageview(pageToLog);
	},

	logEcommercePurchase: (orderId, fullAmount) => {
		if (!isAnalyticsAllowed) {
			console.log("Log ecommerce:");
			console.log(orderId, fullAmount);
			return;
		}

		window.gtag('event', 'conversion', {
			'send_to': 'AW-697368105/ZHNyCJG577IBEKn8w8wC',
			'value': fullAmount,
			'currency': 'USD',
			'transaction_id': orderId
		});

		ReactGA.plugin.execute('ec', 'addProduct', {
			name: 'Holiday Glow Package 2019',
			price: fullAmount
		});

		ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
			id: orderId,
			revenue: fullAmount
		});
	},
};

const FacebookAnalyticsWrapper = {
	init: () => {
		if (isAnalyticsAllowed) {
			ReactFB.init("412704783013592");
		}
	},

	logPageView: () => {
		if (!isAnalyticsAllowed) {
			return;
		}

		ReactFB.track('ViewContent');
	},

	logEvent: (name, data) => {
		if (!isAnalyticsAllowed) {
			return;
		}

		ReactFB.track(name, data);
	},

	logEcommercePurchase: (fullAmount) => {
		if (!isAnalyticsAllowed) {
			return;
		}

		ReactFB.track('Purchase', {
			value: fullAmount,
			currency: 'USD'
		});
	}
};

export const Analytics = {
	init: () => {
		GoogleAnalyticsWrapper.init();
		FacebookAnalyticsWrapper.init();
		FacebookAnalyticsWrapper.logPageView();
	},

	logCurrentURL: () => {
		GoogleAnalyticsWrapper.logPageView(window.location.pathname + window.location.search);
	},

	// ARGS:
	//  pagename: string
	logPageView: (pagename) => {
		GoogleAnalyticsWrapper.logPageView("/" + pagename + window.location.search);
		FacebookAnalyticsWrapper.logPageView();
	},

	// ARGS:
	//  name: string
	logPackageStarted: (name) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Start Package",
			label: name
		});
	},

	logNewOrderClicked: () => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "New Order",
			label: "Landing Page"
		});
	},

	logContinueOrderClicked: () => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Continue Order",
			label: "Landing Page"
		});
	},

	// ARGS:
	//  sectionName: string
	logSectionChange: (sectionName) => {
		Analytics.logPageView(sectionName)
	},

	// ARGS:
	//  sectionName: string
	logSaveButtonPressed: (sectionName) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Save Clicked",
			label: sectionName
		});
	},

	// ARGS:
	//  sectionName: string
	//  quantity: int
	logQuantityEnteredForBuilderSection: (sectionName, quantity) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Builder Quantity Entered",
			label: sectionName + ": " + quantity
		});
	},

	// ARGS:
	//  sectionName: string
	logQuantitySkippedForBuilderSection: (sectionName) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Builder Quantity Skipped",
			label: sectionName
		});
	},

	// ARGS:
	//  sectionName: string
	logAddAnotherItemForBuilderSection: (sectionName) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Builder Add Another Item",
			label: sectionName
		});
	},

	// ARGS:
	//  sectionName: string
	//  index: int
	logBuilderItemStartedDesgining: (sectionName, index) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Builder Item Started Designing",
			label: sectionName + ": Row " + (index + 1)
		})
	},

	// ARGS:
	//  categoryName: string
	//  itemName: string
	logBuilderItemSelected: (categoryName, itemName) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Builder Item Selected",
			label: categoryName + ": " + itemName
		});
	},

	// ARGS:
	//  sectionName: string
	//  index: int
	logBuilderItemRemovedFromSection: (sectionName, index) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Builder Item Removed",
			label: sectionName + ": Row " + (index + 1)
		});
	},

	// ARGS:
	//  categoryName: string
	//  itemName: string
	logItemSelected: (categoryName, itemName) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Item Selected",
			label: categoryName + ": " + itemName
		});
	},

	// ARGS:
	//  categoryName: string
	//  itemName: string
	//  newQuantity: string
	logQuantityChangedForItem: (categoryName, itemName, newQuantity) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Item Quantity Changed",
			label: itemName + ": " + newQuantity
		});
	},

	// ARGS:
	//  categoryName: string
	//  itemName: string
	logSelectableItemRemovedFromCart: (categoryName, itemName) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Item Removed From Cart",
			label: categoryName + ": " + itemName
		});
	},

	// ARGS:
	//  itemName: string
	logItemRemovedFromCart: (itemName) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Item Removed From Cart",
			label: itemName
		});
	},

	// ARGS:
	//  size: string
	logPackageHomeSizePicked: (size) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Package Home Size Picked",
			label: size
		});
	},

	// ARGS:
	//  theme: string
	logPackageThemePicked: (theme) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Package Theme Picked",
			label: theme
		});
	},

	// ARGS:
	//  upsell: string
	logPackageUpsellSelected: (upsell) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Package Upsell Selected",
			label: upsell
		});
	},

	// ARGS:
	//  isSetupDate: bool
	//  dateAsString: string
	logDateSelected: (isSetupDate, dateAsString) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.OrderBuilding,
			action: "Date Selected",
			label: (isSetupDate ? "Setup: " : "Teardown: ") + dateAsString
		});
	},

	// ARGS:
	//  isDeposit: bool
	logCheckoutPressed: (isDeposit, fullAmount) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.Checkout,
			action: "Pay Button Clicked",
			label: isDeposit ? "Deposit" : "Full Amount"
		});

		FacebookAnalyticsWrapper.logEvent('InitiateCheckout', {
			value: fullAmount,
			currency: 'USD'
		});
	},

	logCheckoutSubmitted: () => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.Checkout,
			action: "Order Submitted",
			nonInteraction: true
		});
	},

	// ARGS:
	//  orderId: string -- stripe customer id
	//  isDeposit: bool
	//  fullAmount: int -- in dollars
	logCheckoutSuccess: (orderId, isDeposit, fullAmount) => {
		FacebookAnalyticsWrapper.logEcommercePurchase(fullAmount);
		GoogleAnalyticsWrapper.logEcommercePurchase(orderId, fullAmount);
		GoogleAnalyticsWrapper.logEvent({
			category: Category.Checkout,
			action: "Order Success",
			label: orderId + ": " + (isDeposit ? "Deposit" : "Full Amount") + ", $" + fullAmount,
			nonInteraction: true
		});

	},

	// ARGS:
	//  reason: string
	logCheckoutFailed: (reason) => {
		GoogleAnalyticsWrapper.logEvent({
			category: Category.Checkout,
			action: "Order Failed",
			label: reason,
			nonInteraction: true
		});
	}
};
