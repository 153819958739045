import React, { Component } from 'react';
import styled from 'styled-components';
import ItemBlocks from "./ItemBlocks";
import PropTypes from 'prop-types';
import SectionHeader from "./SectionHeader";
import SectionSubHeader from "./SectionSubHeader";

const SectionContainer = styled.div`
	border-bottom: 1px lightgrey solid;
	padding-top: 20px;
	padding-bottom: 20px;
	
	:last-child {
		border-bottom: none;
	}
`;

const ItemBlockContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
`;

class CategoryContainer extends Component {
	render() {
		const sectionCategoryTypes = this.props.section.categories || [];
		const categoriesToRender = this.props.allCategories.filter(category => {
			return sectionCategoryTypes.includes(category.type);
		});

		return (
			<div>
				{this.renderCategories(categoriesToRender)}
			</div>
		);
	}

	renderCategories(categoriesToRender) {
		return categoriesToRender.map (category => (
				<SectionContainer key={category.type}>
					<SectionHeader>
						{category.humanTitle}
					</SectionHeader>
					{this.renderSubtitle(category.subtitle)}
					<ItemBlockContainer>
						{this.renderItemBlocksFor(categoriesToRender, category)}
					</ItemBlockContainer>
				</SectionContainer>
			)
		);
	}

	renderSubtitle(subtitle) {
		if (subtitle) {
			return <SectionSubHeader>{subtitle}</SectionSubHeader>
		}
	}

	renderItemBlocksFor(categoriesToRender, category) {
		let imageBasePath = "";

		if (category.imagesRelatedTo) {
			const relatedCategory = categoriesToRender.filter(innerCat => { return innerCat.categoryName === category.imagesRelatedTo })[0] || {};
			const selectedItem = (relatedCategory.items || []).filter(item => item.selected)[0] || {};
			imageBasePath = selectedItem.imageBasePath;
		}

		return (
			<ItemBlocks
				key={category.categoryName}
				imageBasePath={imageBasePath}
				category={category}
				isMobile={this.props.isMobile}
				sectionDataName={this.props.section.data}
				isPartOfQuantityBuilder={this.props.section.type === "builder" && !category.selectable}
				onChangeSelection={this.props.onChangeSelection}
				onChangeQuantity={this.props.onChangeQuantity}
			/>
		);
	}
}

CategoryContainer.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	section: PropTypes.object.isRequired,
	allCategories: PropTypes.array.isRequired,
	onChangeQuantity: PropTypes.func.isRequired,
	onChangeSelection: PropTypes.func.isRequired
};

export default CategoryContainer;
