import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Style from "../constants/style";
import SectionHeader from "./SectionHeader";
import SectionSubHeader from "./SectionSubHeader";

const Container = styled.div`
	width: 100%;
	text-align: center;
	margin: 0 auto ${props => props.tight ? 100 : 400}px auto;
	max-width: 800px;
	padding-top: 20px;
`;

const TextArea = styled.textarea`
	border-radius: ${Style.borderRadius}px;
	width: 90%;
  max-width: calc(100% - 64px) !important;
  height: 120px;
  border: 2px solid grey;
  padding: 16px;
  font-family: inherit;
  font-size: larger;
  line-height: inherit;
`;

class LargeTextBox extends Component {
	render() {
		return (
			<Container tight={this.props.tight}>
				<SectionHeader>{this.props.title}</SectionHeader>
				<SectionSubHeader>{this.props.subtitle}</SectionSubHeader>
				<TextArea {...this.props} onChange={(e) => this.props.onChange(e.target.value)}/>
			</Container>
		);
	}
}

LargeTextBox.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	tight: PropTypes.bool.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};

LargeTextBox.defaultProps = {
	tight: false
};

export default LargeTextBox;
